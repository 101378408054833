import { useQuery } from "@apollo/client";
import { INC_INV_OVERVIEW } from "./graphql";
import Spinner from "components/Spinner";
import Errors from "components/Errors";
import React, { useState } from "react";
import CharlesButton from "components/charles/base";
import { useModals } from "ModalProvider";
import InboundShipmentsView from "./InboundShipmentsView";
import ForecastInput from "./ForecastInput";

const InventoryOverview = ({ category, searchText }) => {
  const qtyTypes = [
    "begin_inv",
    "inbound",
    "ecom_transfer",
    "forecast",
    "actual_sales",
    "end_inv",
  ];

  const { loading, error, data } = useQuery(INC_INV_OVERVIEW, {
    variables: { categoryId: category.id },
  });
  const modal = useModals();
  const [editedForecast, setEditedForecast] = useState({});

  if (loading) return <Spinner />;
  if (error) return <Errors error={error} />;

  let initalRes = JSON.parse(data.incInventoryOverview);

  if (searchText) {
    initalRes = Object.keys(initalRes)
      .filter((key) => {
        const x = key
          .toLowerCase()
          .replace(/_/g, "")
          .replace(/-/g, "")
          .replace(/\s/g, "");
        return x.includes(searchText.toLowerCase());
      })
      .reduce((obj, key) => {
        obj[key] = initalRes[key];
        return obj;
      }, {});
  }

  function showShipments({ sku, month, shipments }) {
    modal.present({
      title: "Inbound Shipments",
      subtitle: `${sku} - Month ${month} shipments`,
      children: <InboundShipmentsView shipments={shipments} />,
    });
  }

  const res = Object.entries(initalRes).reduce((acc, [key, yearData]) => {
    const computedValule = Object.entries(yearData).reduce(
      (acc, [month, value]) => {
        let begin_inv = value.begin_inv;
        const end_inv_last_month = acc[month - 1]?.end_inv || 0;
        if (begin_inv === null) begin_inv = end_inv_last_month;

        let end_inv = begin_inv + value.inbound;
        if (value.actual_sales !== null) {
          end_inv -= value.actual_sales;
        } else {
          let forecastedSales = value.forecast;
          const editedForecastValue = editedForecast[key]?.[month];
          if (editedForecastValue) {
            forecastedSales = editedForecastValue;
          }
          end_inv -= forecastedSales;
        }

        acc[month] = { ...value, begin_inv, end_inv };
        return acc;
      },
      {},
    );
    acc[key] = computedValule;
    return acc;
  }, {});

  console.log("res", res);

  const cellStyle = (number) =>
    number < 0 ? "text-red-600 dark:text-red-700" : "";

  return (
    <table className="border-collapse text-sm">
      <thead>
        <tr className="sticky top-0 left-0 z-20">
          <th
            colSpan={2}
            className="sticky top-0 left-0 z-20 border bg-blue-100 dark:border-blue-800 dark:bg-blue-900 px-3"
          >
            Month / SKU
          </th>
          {Object.keys(res).map((key, index) => (
            <th
              className="py-3 px-6 text-right border bg-blue-100 dark:border-blue-800 dark:bg-blue-900 z-10"
              key={index}
            >
              {key}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {Array(12)
          .fill()
          .map((_, i) => (
            <React.Fragment key={i}>
              <tr>
                <td
                  className="text-center text-xl bg-teal-100 dark:bg-teal-900 sticky left-0 z-10 border dark:border-teal-800"
                  rowSpan={qtyTypes.length + 1}
                >
                  {i + 1}
                </td>
              </tr>
              {qtyTypes.map((qtyType, index) => (
                <tr key={index}>
                  <td className="bg-teal-50 dark:bg-teal-900 border dark:border-teal-800 capitalize left-[37.77px] z-10 sticky">
                    {qtyType.replace("_", " ")}
                  </td>
                  {Object.keys(res).map((key, index) => (
                    <td
                      className={`text-right border dark:border-gray-700 bg-white dark:bg-gray-800 px-6
                        ${
                          qtyType === "end_inv" &&
                          cellStyle(res[key][i + 1][qtyType])
                        }
                        `}
                      key={index}
                    >
                      {qtyType === "inbound" && res[key][i + 1][qtyType] > 0 ? (
                        <div className="flex justify-end">
                          <CharlesButton
                            onClick={() =>
                              showShipments({
                                sku: key,
                                month: i + 1,
                                shipments: res[key][i + 1].inbound_shipments,
                              })
                            }
                          >
                            {res[key][i + 1][qtyType]}
                          </CharlesButton>
                        </div>
                      ) : qtyType === "forecast" &&
                        // is later months
                        i + 1 >= new Date().getMonth() + 1 ? (
                        <ForecastInput
                          sku={key}
                          category={category}
                          yearMonth={`${category.year}-${String(i + 1).padStart(2, "0")}`}
                          initialValue={res[key][i + 1][qtyType]}
                          onChangeForecast={(qty) =>
                            setEditedForecast((prev) => ({
                              ...prev,
                              [key]: { ...prev[key], [i + 1]: qty },
                            }))
                          }
                        />
                      ) : (
                        res[key][i + 1][qtyType]
                      )}
                    </td>
                  ))}
                </tr>
              ))}
              <tr>
                <td></td>
              </tr>
            </React.Fragment>
          ))}

        <tr className="sticky bottom-0 z-30 font-bold">
          <td
            className="bg-teal-50 dark:bg-teal-900 border dark:border-teal-800 capitalize left-0 sticky"
            colSpan={2}
          >
            End of the Year
          </td>
          {Object.keys(res).map((key, index) => (
            <td
              className={`text-right px-6 border dark:border-gray-700 bg-white dark:bg-gray-800
                ${cellStyle(res[key][12].end_inv)}
                `}
              key={index}
            >
              {res[key][12].end_inv}
            </td>
          ))}
        </tr>
      </tbody>
    </table>
  );
};

export default InventoryOverview;
