import { useQuery } from "@apollo/client";
import Errors from "components/Errors";
import Page from "components/Page";
import Spinner from "components/Spinner";
import Status from "components/Status";
import CharlesButton from "components/charles/base";
import moment from "moment";
import { Link, useNavigate } from "react-router-dom";
import { FETCH_ASSESSMENTS } from "./graphql";
import { SCORE_COLORS } from "./const";
import { useModals } from "ModalProvider";
import CreateAssessmentView from "./CreateAssessmentView";

const AssessmentList = () => {
  const { data, error, loading } = useQuery(FETCH_ASSESSMENTS);
  const modal = useModals();
  const navigate = useNavigate();

  if (loading) return <Spinner />;
  if (error) return <Errors error={error} />;

  const assessments = data.assessments.edges;

  function createAssessment() {
    modal.present({
      title: "Create Assessment",
      center: true,
      maxWidth: "max-w-3xl",
      children: <CreateAssessmentView complete={didCreateAssessment} />,
    });
  }

  function didCreateAssessment(assessment) {
    modal.hide();
    navigate(assessment.id);
  }

  return (
    <Page
      title="Assessment"
      rightButtons={
        <div className="space-x-4 flex">
          <CharlesButton onClick={createAssessment}>
            + Create Assessment
          </CharlesButton>
          <Link to="/assessment/reports">Reports</Link>
        </div>
      }
    >
      <div className="px-6 py-4 flex flex-col flex-1">
        <div className="opacity-70">
          ESG is important to us. This assessment System is to help us to assess
          our suppliers' ESG performance. <br />
          You manage the assessment questions in admin interface. Each question
          could belong to a parent category. The root category holds all the
          child categories and questions for a assessment. Typicall we organize
          the asseesment once a year -- for example: 2024 Assessment. <br />
          In Waboba, we do on site audit for our suppliers every year. Within
          the system, we will do the following steps:
          <p>
            1. Create an assessment, send to our supplier and ask them to do
            self assessment.
          </p>
          <p>
            2. Once the supplier complete the self assessment, we will review
            the answers and give a score to each question. If the score is not
            good enough, we might provide some rectification suggestions to the
            supplier.
          </p>
          <p>
            3. Then the supplier should provide rectification plan and timeline.
            We will review the rectification plan and timeline. Some questions
            might be marked as "Follow up next time" if neccessary. The follow
            up questions information will be automatically attached to the next
            assessment for the supplier.
          </p>
          <p>
            Supplier can submit and follow up assessment in supplier portal.
          </p>
        </div>

        <div className="card px-4 py-2 mt-4 whitespace-nowrap">
          <table>
            <thead>
              <tr>
                <th>Assessment</th>
                <th className="w-full">Supplier</th>
                <th>Status</th>
                <th>Due Date</th>
                <th className="text-right">Score</th>
                <th className="text-right">Created By</th>
              </tr>
            </thead>
            <tbody>
              {assessments.map((a) => {
                const baseInfo = JSON.parse(a.node.baseInfo);

                return (
                  <tr
                    key={a.node.id}
                    className="border-t border-gray-100 dark:border-gray-700"
                  >
                    <td>
                      <Link to={a.node.id}>
                        {a.node.category ? a.node.category.name : "-"}
                      </Link>
                    </td>
                    <td>
                      {a.node.supplier.name}
                      {baseInfo && baseInfo.name && (
                        <span className="text-xs opacity-70">
                          ({baseInfo.name})
                        </span>
                      )}
                    </td>
                    <td className="p-0">
                      <Status status={a.node.status} />
                    </td>

                    <td>
                      {a.node.dueDate
                        ? moment(a.node.dueDate).format("YYYY-MM-DD")
                        : "N/A"}
                    </td>
                    <td
                      className={`text-right font-bold
                    ${SCORE_COLORS[a.node.totalScore.scoreText]}
                  `}
                    >
                      {a.node.supplier.name === "Charlie's Factory" ? (
                        <div className="flex justify-end">
                          <div
                            className="text-pink-600 border border-pink-600 flex items-center justify-center w-4 h-4 rounded-full"
                            style={{ fontSize: 9 }}
                          >
                            S
                          </div>
                        </div>
                      ) : (
                        a.node.totalScore.scoreText
                      )}
                    </td>
                    <td className="text-right">{a.node.createdBy.email}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </Page>
  );
};

export default AssessmentList;
