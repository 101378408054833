import CustomTooltip from "components/CustomTooltip";
import {
  ResponsiveContainer,
  Bar,
  YAxis,
  XAxis,
  Legend,
  Tooltip,
  CartesianGrid,
  ComposedChart,
  Line,
} from "recharts";

const AssessmentScoresByCategoryView = ({ data }) => {
  const keys = Object.keys(data[0]).filter(
    (key) => key !== "supplier" && key !== "Average Score",
  );
  const colors = ["#84cc16", "#14b8a6", "#f97316"];

  return (
    <div className="card px-8 py-6">
      <h4>Supplier Assessment Scores</h4>
      <div>
        Average scores of suppliers by category. The scores are out of 100.
      </div>
      <div className="mt-6">
        <ResponsiveContainer height={800}>
          <ComposedChart data={data} barCategoryGap="20%" layout="vertical">
            {keys.map((key, index) => (
              <Bar key={key} dataKey={key} fill={colors[index]} />
            ))}
            <XAxis type="number" label />
            <YAxis
              width={160}
              height={60}
              dataKey="supplier"
              type="category"
              interval={0}
            />
            <Line dataKey="Average Score" stroke="#4f46e5" strokeWidth={4} />
            <CartesianGrid strokeDasharray="3 3" />
            <Tooltip content={CustomTooltip} cursor={{ fillOpacity: 0.1 }} />
            <Legend />
          </ComposedChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default AssessmentScoresByCategoryView;
