import { useMutation } from "@apollo/client";
import { Select } from "components/Form";
import { Alert } from "components/Toast";
import { INC_SAVE_INVENTORY_ITEMS } from "./graphql";
import { parseError } from "apollo";
import { useState } from "react";
import CharlesButton from "components/charles/base";
import moment from "moment";

const ImportBeginInventoryView = ({ category, items, hide }) => {
  const [month, setMonth] = useState(new Date().getMonth());
  const [save, saveRes] = useMutation(INC_SAVE_INVENTORY_ITEMS, {
    onCompleted() {
      Alert("success", "Inventory Updated.");
      hide();
    },
    onError(error) {
      Alert("error", parseError(error));
    },
    refetchQueries: ["INC_INV_OVERVIEW"],
    awaitRefetchQueries: true,
  });

  function confirmHandler() {
    const date = moment().set("month", month).toDate();
    // set to the first day of the month
    const firstDay = moment(date).startOf("month").toDate();
    save({
      variables: {
        items,
        warehouse: category.warehouse,
        date: moment(firstDay).format("YYYY-MM-DD"),
        type: "inventory",
      },
    });
  }

  return (
    <div>
      <div className="card p-0 overflow-auto max-h-96 -mx-1">
        <table>
          <thead>
            <tr className="sticky top-0 z-10 bg-gray-100">
              <th className="px-6 py-3">SKU ({items.length})</th>
              <th className="px-6 text-right">Quantity</th>
            </tr>
          </thead>
          <tbody>
            {items.map((item, index) => (
              <tr
                key={index}
                className="border-y border-gray-100 dark:border-gray-700"
              >
                <td className="px-6">{item.sku}</td>
                <td className="px-6 text-right">{item.qty}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="mt-6 justify-between flex">
        <div className=" space-x-3">
          <label htmlFor="">Month:</label>
          <Select value={month} onChange={(e) => setMonth(e.target.value)}>
            {Array(12)
              .fill()
              .map((_, i) => (
                <option key={i} value={i}>
                  {i + 1}
                </option>
              ))}
          </Select>
        </div>

        <CharlesButton
          onClick={confirmHandler}
          loading={saveRes.loading}
          primary
        >
          Confirm Import
        </CharlesButton>
      </div>
    </div>
  );
};

export default ImportBeginInventoryView;
