import { gql, useQuery } from "@apollo/client";
import { parseError } from "apollo";
import Errors from "components/Errors";
import Spinner from "components/Spinner";
import { Route, Routes } from "react-router-dom";
import Suppliers from "./Suppliers";
import LumpSumSupplierView from "./LumpSumSupplierView";

const FETCH_OS_LUMP_SUM = gql`
  query FETCH_OS_LUMP_SUM {
    osLumpSum
    allProductionPlans(
      statesIn: ["WAITING_TO_CONFIRM", "PLANNING"]
      options: { orderBy: "join_queue_at" }
    ) {
      results {
        id
        name
        wisCustomer {
          id
          name
        }
        startDate
        readyDate
      }
      total
    }
  }
`;

const LumpSumPage = () => {
  const { loading, error, data } = useQuery(FETCH_OS_LUMP_SUM);

  if (loading) return <Spinner />;
  if (error) return <Errors error={error} />;

  const suppliers = JSON.parse(data.osLumpSum);

  console.log("suppliers", suppliers);

  return (
    <Routes>
      <Route>
        <Route index element={<Suppliers suppliers={suppliers} />} />
        <Route
          path=":supplierId"
          element={
            <LumpSumSupplierView
              plans={data.allProductionPlans.results}
              suppliers={suppliers}
            />
          }
        />
      </Route>
    </Routes>
  );
};

export default LumpSumPage;
