import { useState } from "react";
import { useQuery } from "@apollo/client";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  ReferenceLine,
  Legend,
  LineChart,
  Line,
  CartesianGrid,
} from "recharts";
import Page from "components/Page";
import {
  FETCH_SUPPLIER_INDICATOR_INFOS,
  FETCH_COMMUNICATION_INFOS_BY_SUPPLIER,
  FETCH_SHIPMENTERROR_INFOS_BY_SUPPLIER,
  FETCH_SUPPLIER_INDICATOR_CHNAGE_HISTORY,
  FETCH_SUPPLIER_QUALITY_PRODUCT_DETAILS,
} from "./graphql";
import { useNavigate } from "react-router-dom";
import DateRangePicker from "../DateRangePicker";
import useDateRange from "hooks/useDateRange";
import { formatDate } from "react-day-picker/moment";
import Spinner from "components/Spinner";
import Errors from "components/Errors";
import CharlesButton, { CharlesButtonWithArrow } from "components/charles/base";

const COLORS = [
  "#5ed6ef",
  "#b68bb1",
  "#f3bc2d",
  "#dcaf86",
  "#1a6d75",
  "#5784f8",
  "#31352a",
  "#469297",
  "#f24122",
  "#3dd045",
];

const DetailInfoTable = ({ indicator, data, handleRowClick }) => {
  const navigate = useNavigate();
  const showExtraColumns = ["Quality", "LeadTime", "ShipmentError"].includes(
    indicator,
  );

  const validRecords = data.filter((record) => record.score !== -1);

  const handleClick = (record) => {
    if (indicator === "ESG") {
      navigate("/assessment/" + record.assessmentId);
    } else {
      handleRowClick(record);
    }
  };

  const renderExtraHeader = () => {
    const headersMap = {
      Quality: ["Defect Rate", "Issue Qty", "Check Qty"],
      LeadTime: ["Ontime Rate", "Ontime Qty", "Total Qty"],
      ShipmentError: ["Error Rate", "Error Qty", "Total Qty"],
    };

    const headers = headersMap[indicator] || [];
    return headers.map((header) => (
      <th className="px-6 py-3 text-gray-500 uppercase" key={header}>
        {header}
      </th>
    ));
  };

  return (
    <div className="card overflow-auto">
      <table
        data-testid="grading-tab-table"
        className="min-w-full divide-y divide-gray-200"
      >
        <thead>
          <tr>
            <th className="px-6 py-3 text-gray-500 uppercase">Supplier</th>
            <th className="px-6 py-3 text-gray-500 uppercase">Score</th>
            {renderExtraHeader()}
          </tr>
        </thead>

        <tbody className="divide-y divide-gray-200">
          {data.map((record) => {
            const isRowClickable = record.score !== -1;

            return (
              <tr
                key={record.supplierName}
                className={
                  isRowClickable
                    ? "cursor-pointer hover:bg-gray-200 hover:dark:bg-gray-700"
                    : ""
                }
                onClick={isRowClickable ? () => handleClick(record) : undefined}
              >
                <td className="px-6 py-4 text-sm text-gray-500 ">
                  {record.supplierName}
                </td>
                <td className="px-6 py-4 text-sm text-gray-500 ">
                  {record.score !== -1 ? record.score : "/"}
                </td>
                {showExtraColumns && (
                  <>
                    <td className="px-6 py-4 text-sm text-gray-500 ">
                      {record.rate !== -1 ? record.rate : "/"}
                    </td>
                    <td className="px-6 py-4 text-sm text-gray-500 ">
                      {record.cnt}
                    </td>
                    <td className="px-6 py-4 text-sm text-gray-500 ">
                      {record.totalCnt}
                    </td>
                  </>
                )}
              </tr>
            );
          })}

          {/* Add a row for averages */}
          {data.length > 0 && (
            <tr className="font-bold">
              <td className="px-6 py-4 text-sm text-gray-500 ">Average</td>
              <td className="px-6 py-4 text-sm text-gray-500 ">
                {(
                  validRecords.reduce((sum, record) => sum + record.score, 0) /
                  validRecords.length
                ).toFixed(2)}
              </td>
              {showExtraColumns && (
                <td className="px-6 py-4 text-sm text-gray-500 ">
                  {(
                    validRecords.reduce((sum, record) => sum + record.rate, 0) /
                    validRecords.length
                  ).toFixed(2)}
                </td>
              )}
            </tr>
          )}
        </tbody>
      </table>

      {indicator === "ESG" && (
        <div>
          <p className="text-gray-700 opacity-70 dark:text-gray-400">
            Note: Little Land(Acrowork)'s ESG score here is the average of
            Little Land and XUFENG!
          </p>
        </div>
      )}
    </div>
  );
};

const ScoreRateChart = ({ indicator, data }) => {
  const [visibleBars, setVisibleBars] = useState({
    score: true,
    rate: true,
  });

  const averageScore = Number(
    (
      data.reduce(
        (sum, record) => sum + (record.score !== -1 ? record.score : 0),
        0,
      ) / data.length
    ).toFixed(2),
  );
  // ESG and Communication don't have rate
  const averageRate = ["ESG", "Communication"].includes(indicator)
    ? null
    : Number(
        (
          data.reduce(
            (sum, record) => sum + (record.rate !== -1 ? record.rate : 0),
            0,
          ) / data.length
        ).toFixed(2),
      );

  const handleLegendClick = (e) => {
    setVisibleBars({
      ...visibleBars,
      [e.dataKey]: !visibleBars[e.dataKey],
    });
  };

  return (
    <div className="card overflow-x-auto min-w-max">
      <ResponsiveContainer
        width="100%"
        height="100%"
        minWidth={300}
        minHeight={300}
        className="justify-center items-center"
      >
        <BarChart data={data}>
          <XAxis
            dataKey="supplierName"
            interval={0}
            angle={10}
            textAnchor="start"
          />
          <YAxis
            yAxisId="left"
            orientation="left"
            stroke="#5ed6ef"
            strokeOpacity={0.8}
            domain={[0, 100]}
          />
          {averageRate !== null && (
            <YAxis
              yAxisId="right"
              orientation="right"
              stroke="#f3bc2d"
              strokeOpacity={0.8}
            />
          )}
          <Tooltip />
          <Legend onClick={handleLegendClick} />

          <Bar
            yAxisId="left"
            dataKey="score"
            fill="#5ed6ef"
            fillOpacity={0.8}
            name="Score"
            hide={!visibleBars.score}
          />
          {averageRate !== null && (
            <Bar
              yAxisId="right"
              dataKey="rate"
              fill="#f3bc2d"
              fillOpacity={0.8}
              name="Rate"
              hide={!visibleBars.rate}
            />
          )}

          <ReferenceLine
            y={averageScore}
            yAxisId="left"
            hide={!visibleBars.score}
            stroke="#4da6bf"
            strokeOpacity={0.8}
            strokeDasharray="6 6"
            label={{
              value: "avg score " + averageScore,
              position: "insideBottomLeft",
              fill: "#4da6bf",
              fontSize: 14,
            }}
          />
          {averageRate !== null && (
            <ReferenceLine
              y={averageRate}
              yAxisId="right"
              hide={!visibleBars.rate}
              stroke="#e3ac1d"
              strokeOpacity={0.8}
              strokeDasharray="3 3"
              label={{
                value: "avg rate " + averageRate,
                position: "insideBottomRight",
                fill: "#d39c0d",
                fontSize: 14,
              }}
            />
          )}
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

const SupplierShipmentError = ({ supplierInfo, start, end }) => {
  const [showShipments, setShowShipments] = useState({});

  const { loading, error, data } = useQuery(
    FETCH_SHIPMENTERROR_INFOS_BY_SUPPLIER,
    {
      variables: {
        supplierId: supplierInfo.supplierId,
        startDate: formatDate(start, "YYYY-MM-DD"),
        endDate: formatDate(end, "YYYY-MM-DD"),
      },
    },
  );

  if (loading) return <Spinner />;
  if (error) return <Errors error={error} />;

  // the keys are the combination of error codes
  // the values are an array containing all shipmentErrorInfo objects with the same error code
  const errorsByCode = data.shipmentErrorInfosBySupplier.reduce(
    (acc, shipmentErrorInfo) => {
      const { error } = shipmentErrorInfo.shipmentError;
      const errorCode = `${error.errorCategory.code}${error.code}`;

      if (!acc[errorCode]) {
        acc[errorCode] = [];
      }

      acc[errorCode].push(shipmentErrorInfo);

      return acc;
    },
    {},
  );

  return (
    <div data-testid="shipmenterror-details">
      <h4>Details in Selected Date Range</h4>
      {Object.keys(errorsByCode).length !== 0 ? (
        Object.entries(errorsByCode).map(([errorCode, shipmentErrorInfos]) => (
          <div key={errorCode} className="mb-2">
            <div className="flex text-sm">
              <div className="flex-none">Qty: {shipmentErrorInfos.length}</div>
              <div className="mx-2">-</div>
              <div className="font-bold text-pink-500">{errorCode}</div>
              <div className="mx-2">-</div>
              <div className="text-gray-700 dark:text-gray-400">
                {shipmentErrorInfos[0].shipmentError.error.description}
              </div>
            </div>

            <CharlesButtonWithArrow
              onClick={() =>
                setShowShipments((prev) => ({
                  ...prev,
                  [errorCode]: !prev[errorCode],
                }))
              }
              className="text-blue-600 justify-center"
              show={showShipments[errorCode]}
            >
              Show Shipments
            </CharlesButtonWithArrow>

            {showShipments[errorCode] &&
              shipmentErrorInfos.map((shipmentErrorInfo, index) => {
                const {
                  shipmentStatus: { shipment },
                } = shipmentErrorInfo.shipmentError;
                return (
                  <div key={index} className="flex space-x-2 my-2">
                    <CharlesButton
                      onClick={() =>
                        window.open(
                          `/shipment/shipments/${shipment.id}`,
                          "_blank",
                        )
                      }
                    >
                      {`#${shipment.invoiceNumber} ${shipment.po ? `, PO: ${shipment.po}` : ""} ${shipment.customer.name}`}
                    </CharlesButton>
                  </div>
                );
              })}
          </div>
        ))
      ) : (
        <div>
          <p className="text-gray-700 dark:text-gray-400">
            No shipment errors found in the selected date range.
          </p>
        </div>
      )}
    </div>
  );
};

const SupplierCommunication = ({ supplierInfo, start, end }) => {
  const [showSuveryRespondents, setShowSuveryRespondents] = useState({});

  const { loading, error, data } = useQuery(
    FETCH_COMMUNICATION_INFOS_BY_SUPPLIER,
    {
      variables: {
        supplierId: supplierInfo.supplierId,
        startDate: formatDate(start, "YYYY-MM-DD"),
        endDate: formatDate(end, "YYYY-MM-DD"),
      },
    },
  );

  if (loading) return <Spinner />;
  if (error) return <Errors error={error} />;

  return (
    <div className="card">
      <h3>{supplierInfo.supplierName}</h3>

      <div className="my-4">
        <p className="text-sm font-bold">Reference Surveys:</p>
        <ul className="list-disc list-inside">
          {data.communicationInfosBySupplier.surveys.map((survey) => (
            <div className="text-sm" key={survey.id}>
              <div>
                <CharlesButtonWithArrow
                  className="text-blue-600 justify-center"
                  onClick={() =>
                    setShowSuveryRespondents((prev) => ({
                      ...prev,
                      [survey.id]: !prev[survey.id],
                    }))
                  }
                  show={showSuveryRespondents[survey.id]}
                >
                  {survey.title}
                </CharlesButtonWithArrow>

                {showSuveryRespondents[survey.id] && (
                  <div>
                    {data.communicationInfosBySupplier.respondents.map(
                      (respondent) => {
                        if (respondent.surveyId === survey.id) {
                          return (
                            <div key={respondent.surveyId}>
                              <div className="ml-2 font-bold">
                                Respondent Qty: {respondent.users.length}
                              </div>
                              <div className="ml-2 ">
                                {respondent.users.join(", ")}
                              </div>
                            </div>
                          );
                        }
                      },
                    )}
                  </div>
                )}
              </div>
            </div>
          ))}
        </ul>
      </div>

      <div className="my-4">
        <p className="text-sm font-bold">Questions & Answer scores</p>
        {data.communicationInfosBySupplier.qas.map((qa, index) => {
          const percentage = Math.round((qa.answerScore / qa.totalScore) * 100);
          return (
            <div key={index} className="mb-4">
              <div className="text-sm">{qa.questionText}</div>
              <ul className="list-disc list-inside">
                <li className="font-bold">Answer Score: {qa.answerScore}</li>
                <li className="font-bold">Total Score: {qa.totalScore}</li>
              </ul>

              <div className="mt-1 flex justify-between items-center">
                <div className="overflow-hidden rounded bg-gray-300 w-full items-center">
                  <div
                    style={{ width: `${percentage}%` }}
                    className="flex text-center text-white justify-center bg-blue-500"
                  >
                    {percentage}
                  </div>
                </div>

                <div className="ml-4 flex items-center">{percentage}/100</div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const ScoreCalculationRule = ({ indicator }) => {
  return (
    <div className="space-y-4">
      <p className="text-base font-bold">
        {indicator} - Score Calculation Method
      </p>
      {indicator === "Quality" && (
        <div className="space-y-2">
          <p className="text-sm font-bold">About SCORE:</p>
          <p>
            Divide into a total of 8 score intervals, with a range of 60 to 100
            and a benchmark of 80.
          </p>
          <p className="text-sm font-bold">About RATE:</p>
          <p>DEFECT RATE = ISSUE QTY / CHECK QTY</p>
          <p>The defect rate corresponding to score 80 is: 0.0315 </p>
          <p>
            This benchmark rate is taken from the average rate of all suppliers,
            based on statistical data over the past period of time.
          </p>
          <p className="text-sm font-bold">
            The correspondence between scores and rates:
          </p>
          <ul className="list-disc list-inside">
            <li>0 &lt;= defect rate &lt;= 0.0079, the score is 100.</li>
            <li>0.0079 &lt; defect rate &lt;= 0.0158, the score is 95.</li>
            <li>0.0158 &lt; defect rate &lt;= 0.0236, the score is 90.</li>
            <li>0.0236 &lt; defect rate &lt;= 0.0315, the score is 85.</li>
            <li>0.0315 &lt; defect rate &lt;= 0.0394, the score is 80.</li>
            <li>0.0394 &lt; defect rate &lt;= 0.0473, the score is 75.</li>
            <li>0.0473 &lt; defect rate &lt;= 0.051, the score is 70.</li>
            <li>0.051 &lt; defect rate &lt;= 0.063, the score is 65.</li>
            <li>defect rate &gt; 0.063, the score is 60.</li>
          </ul>
        </div>
      )}
      {indicator === "ShipmentError" && (
        <div className="space-y-2">
          <p className="text-sm font-bold">About SCORE:</p>
          <p>
            Divide into a total of 8 score intervals, with a range of 60 to 100
            and a benchmark of 80.
          </p>
          <p className="text-sm font-bold">About RATE:</p>
          <p>ERROR RATE = ERROR QTY / TOTAL QTY</p>
          <p>The error rate corresponding to score 80 is: 0.0176 </p>
          <p>
            This benchmark rate is taken from the average rate of all suppliers,
            based on statistical data over the past period of time.
          </p>
          <p className="text-sm font-bold">
            The correspondence between scores and rates:
          </p>
          <ul className="list-disc list-inside">
            <li>If error rate &lt; 0, the score is 0.</li>
            <li>If 0 &lt;= error rate &lt;= 0.0044, the score is 100.</li>
            <li>If 0.0044 &lt; error rate &lt;= 0.0088, the score is 95.</li>
            <li>If 0.0088 &lt; error rate &lt;= 0.0132, the score is 90.</li>
            <li>If 0.0132 &lt; error rate &lt;= 0.0176, the score is 85.</li>
            <li>If 0.0176 &lt; error rate &lt;= 0.022, the score is 80.</li>
            <li>If 0.022 &lt; error rate &lt;= 0.0264, the score is 75.</li>
            <li>If 0.0264 &lt; error rate &lt;= 0.0308, the score is 70.</li>
            <li>If 0.0308 &lt; error rate &lt;= 0.0352, the score is 65.</li>
            <li>If the error rate &gt; 0.0352, the score is 60.</li>
          </ul>
        </div>
      )}
      {indicator === "LeadTime" && (
        <div className="space-y-2">
          <p className="text-sm font-bold">About SCORE:</p>
          <p>
            Divide into a total of 8 score intervals, with a range of 60 to 100
            and a benchmark of 80.
          </p>
          <p className="text-sm font-bold">About RATE:</p>
          <p>ONTIME RATE = ONTIME QTY / TOTAL QTY</p>
          <p>The ontime rate corresponding to score 80 is: 0.7328 </p>
          <p>
            This benchmark rate is taken from the average rate of all suppliers,
            based on statistical data over the past period of time.
          </p>
          <p className="text-sm font-bold">
            The correspondence between scores and rates:
          </p>
          <ul className="list-disc list-inside">
            <li>
              If ontime rate &lt; 0 or ontime rate &gt; 1, the score is 0.
            </li>
            <li>If 0.9332 &lt;= ontime rate, the score is 100.</li>
            <li>If 0.8664 &lt;= ontime rate &lt; 0.9332, the score is 95.</li>
            <li>If 0.7996 &lt;= ontime rate &lt; 0.8664, the score is 90.</li>
            <li>If 0.7328 &lt;= ontime rate &lt; 0.7996, the score is 85.</li>
            <li>If 0.666 &lt;= ontime rate &lt; 0.7328, the score is 80.</li>
            <li>If 0.5992 &lt;= ontime rate &lt; 0.666, the score is 75.</li>
            <li>If 0.5324 &lt;= ontime rate &lt; 0.5992, the score is 70.</li>
            <li>If 0.4656 &lt;= ontime rate &lt; 0.5324, the score is 65.</li>
            <li>If the ontime rate &lt; 0.4656, the score is 60.</li>
          </ul>
        </div>
      )}
      {indicator === "ESG" && (
        <div className="space-y-2">
          <p className="text-sm font-bold">About SCORE:</p>
          <p>ESG score is calculated based on the ESG assessment result.</p>
        </div>
      )}
      {indicator === "Communication" && (
        <div className="space-y-2">
          <p className="text-sm font-bold">About SCORE:</p>
          <p>
            Communication score is calculated based on the communication surveys
            result.
          </p>
          <p>
            The scores here may come from multiple surveys, depending on the
            selected date range.
          </p>
          <p>
            As long as the valid start and end dates of the survey intersect
            with the selected date range, they will be included in the
            statistics.
          </p>
          <p>Each survey has 7 Questions.</p>
          <p>
            The maximum score for each question is 5 points, and the answers are
            divided into 5 options ranging from 1 to 5 points.
          </p>
          <p>
            To calculate the score, divide the selected score by the total score
            and convert the result to a percentage system. So the score range is
            from 0 to 100 points.{" "}
          </p>
        </div>
      )}
    </div>
  );
};

export const ChangeHistoryLineChart = ({ data }) => {
  return (
    <div data-testid="line-chart">
      <ResponsiveContainer
        width="100%"
        height="100%"
        minWidth={300}
        minHeight={300}
        className="justify-center items-center"
      >
        <LineChart
          data={data}
          width={500}
          height={300}
          margin={{ top: 30, right: 10, left: 10, bottom: 5 }}
        >
          <XAxis dataKey="date" />
          <YAxis
            yAxisId="left"
            orientation="left"
            stroke="#5ed6ef"
            strokeOpacity={0.8}
          />
          <YAxis
            yAxisId="right"
            orientation="right"
            stroke="#f3bc2d"
            strokeOpacity={0.8}
          />
          <Tooltip />
          <Legend />
          <CartesianGrid strokeDasharray="3 3" opacity={0.6} />

          <Line
            yAxisId="left"
            type="monotone"
            dataKey="score"
            stroke="#5ed6ef"
            activeDot={{ r: 8 }}
            label={({ x, y, value }) => (
              <text
                x={x}
                y={y}
                dy={-10}
                fill="#4da6bf"
                fontSize={14}
                textAnchor="middle"
              >
                {value}
              </text>
            )}
            animationDuration={500}
            // connectNulls
          />
          <Line
            yAxisId="right"
            type="monotone"
            dataKey="rate"
            stroke="#f3bc2d"
            activeDot={{ r: 8 }}
            label={({ x, y, value }) => (
              <text
                x={x}
                y={y}
                dy={-10}
                fill="#e3ac1d"
                fontSize={14}
                textAnchor="middle"
              >
                {value}
              </text>
            )}
            animationDuration={500}
            // connectNulls
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

const ChangeHistory = ({ indicator, supplierId }) => {
  const { loading, error, data } = useQuery(
    FETCH_SUPPLIER_INDICATOR_CHNAGE_HISTORY,
    {
      variables: {
        indicator: indicator,
        supplierId: supplierId,
      },
    },
  );

  // if (loading) return <Spinner />;
  if (error) return <Errors error={error} />;

  const lineChartData = data?.supplierIndicatorChangeHistory.map(
    ({ date, score, rate }) => {
      let newItem = { date };

      if (score !== -1) {
        newItem.score = score;
      }

      if (rate !== -1) {
        newItem.rate = rate;
      }

      return newItem;
    },
  );

  return (
    <div data-testid="change-history">
      <h4>Aggregated Trend</h4>
      <p className="text-gray-700 dark:text-gray-400">
        All products' aggregated defect rate over the past 6 months.
      </p>
      {loading ? <Spinner /> : <ChangeHistoryLineChart data={lineChartData} />}
    </div>
  );
};

const QualityPoductDetail = ({ supplierId }) => {
  const [productVisibility, setProductVisibility] = useState({});
  const { loading, error, data } = useQuery(
    FETCH_SUPPLIER_QUALITY_PRODUCT_DETAILS,
    {
      variables: { supplierId: supplierId },
    },
  );

  if (loading) return <Spinner />;
  if (error) return <Errors error={error} />;

  // Transform the data to a format that can be used by the LineChart component
  let productNames = new Set();
  const transformedData = data.supplierQualityProductDetails.reduce(
    (acc, product) => {
      productNames.add(product.name);
      product.defect.forEach((defect) => {
        const existingObject = acc.find((item) => item.date === defect.date);
        if (existingObject) {
          existingObject[product.name] = defect.rate;
        } else {
          acc.push({ date: defect.date, [product.name]: defect.rate });
        }
      });
      return acc;
    },
    [],
  );
  productNames = [...productNames]; // Convert Set to Array for easier usage

  const handleLegendClick = (event) => {
    setProductVisibility({
      ...productVisibility,
      [event.dataKey]: !productVisibility[event.dataKey], // first click, show it
    });
  };

  return (
    <div data-testid="product-detail">
      <h4>Product Defect Rate History</h4>
      <p className="text-gray-700 dark:text-gray-400">Over the past year.</p>
      <div>
        <ResponsiveContainer
          width="100%"
          height="100%"
          minWidth={300}
          minHeight={300}
          className="justify-center items-center"
        >
          <LineChart
            data={transformedData}
            width={500}
            height={300}
            margin={{ top: 30, right: 30, left: 10, bottom: 5 }}
          >
            <XAxis dataKey="date" />
            <YAxis yAxisId="defect-rate" orientation="left" />
            <Tooltip />
            <Legend onClick={handleLegendClick} />
            <CartesianGrid strokeDasharray="3 3" opacity={0.6} />

            {productNames.map((name, index) => {
              const colorMap = productNames.reduce((acc, name, index) => {
                acc[name] = COLORS[index % COLORS.length];
                return acc;
              }, {});

              return (
                <Line
                  key={index}
                  yAxisId="defect-rate"
                  type="monotone"
                  dataKey={name}
                  stroke={colorMap[name]}
                  activeDot={{ r: 8 }}
                  label={({ x, y, value }) => (
                    <text
                      x={x}
                      y={y}
                      dy={-10}
                      fill={colorMap[name]}
                      fontSize={14}
                      textAnchor="middle"
                    >
                      {value}
                    </text>
                  )}
                  animationDuration={500}
                  hide={
                    productVisibility[name] === false ||
                    (index >= 3 && !productVisibility[name])
                  }
                />
              );
            })}
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

const Tab = ({ indicator }) => {
  const [{ start, end }, { setStart, setEnd }] = useDateRange(6);
  const { loading, error, data } = useQuery(FETCH_SUPPLIER_INDICATOR_INFOS, {
    variables: {
      indicator: indicator,
      startDate: formatDate(start, "YYYY-MM-DD"),
      endDate: formatDate(end, "YYYY-MM-DD"),
    },
  });
  const [selectedSupplier, setSelectedSupplier] = useState(null);

  const handleRowClick = (supplierInfo) => {
    setSelectedSupplier(supplierInfo);
  };

  if (loading) return <Spinner />;
  if (error) return <Errors error={error} />;

  return (
    <Page title={indicator} backTo="..">
      <div className="p-6">
        <div>
          <DateRangePicker
            start={start}
            end={end}
            setStart={setStart}
            setEnd={setEnd}
          />
        </div>

        <div className="my-4">
          <ScoreRateChart
            indicator={indicator}
            data={data.supplierIndicatorInfos}
          />
        </div>

        <div className="my-4 flex space-x-4">
          <div className="w-1/2">
            <DetailInfoTable
              indicator={indicator}
              data={data.supplierIndicatorInfos}
              handleRowClick={handleRowClick}
            />
          </div>

          {indicator === "Quality" && selectedSupplier && (
            <div className="w-1/2">
              <div
                data-testid="grading-quality-detail-card"
                className="card space-y-4"
              >
                <h3>{selectedSupplier.supplierName}</h3>
                <ChangeHistory
                  indicator={indicator}
                  supplierId={selectedSupplier.supplierId}
                />
                <QualityPoductDetail supplierId={selectedSupplier.supplierId} />
              </div>
            </div>
          )}
          {indicator === "LeadTime" && selectedSupplier && (
            <div className="w-1/2">
              <div
                data-testid="grading-leadtime-detail-card"
                className="card space-y-4"
              >
                <h3>{selectedSupplier.supplierName}</h3>
                <ChangeHistory
                  indicator={indicator}
                  supplierId={selectedSupplier.supplierId}
                />
              </div>
            </div>
          )}
          {indicator === "ShipmentError" && selectedSupplier && (
            <div className="w-1/2">
              <div
                data-testid="grading-shipmenterror-detail-card"
                className="card space-y-4"
              >
                <h3>{selectedSupplier.supplierName}</h3>
                <ChangeHistory
                  indicator={indicator}
                  supplierId={selectedSupplier.supplierId}
                />
                <SupplierShipmentError
                  supplierInfo={selectedSupplier}
                  start={start}
                  end={end}
                />
              </div>
            </div>
          )}
          {indicator === "Communication" && selectedSupplier && (
            <div className=" w-1/2">
              <SupplierCommunication
                supplierInfo={selectedSupplier}
                start={start}
                end={end}
              />
            </div>
          )}
        </div>

        <div className="my-4">
          <ScoreCalculationRule indicator={indicator} />
        </div>
      </div>
    </Page>
  );
};

export default Tab;
