import { useEffect, useRef, useState } from "react";
import { useQuery } from "@apollo/client";
import { formatDate } from "react-day-picker/moment";
import Spinner from "components/Spinner";
import Errors from "components/Errors";
import SearchBar from "components/SearchBar";
import { searchByProp } from "utils/search.js";
import odooIcon from "assets/odoo-icon.svg";
import { IoMdCheckmarkCircle } from "react-icons/io";
import { Button } from "components/base";
import useDocumentGenerator from "hooks/useDocumentGenerator";
import { Alert } from "components/Toast";
import http from "utils/http";
import OdooWarehouseSelector from "components/OdooWarehouseSelector";
import { useModals } from "ModalProvider";
import OdooOrderDetail from "./Detail";
import { FETCH_ODOO_SO } from "./graphql";
import { FixedSizeList } from "react-window";
import CharlesButton from "components/charles/base";

const OdooInvoiceList = () => {
  const [warehouseId, setWarehouseId] = useState(3);
  const [searchText, setSearchText] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);

  return (
    <div className="p-6 flex flex-col overflow-auto h-full">
      <div>
        <div className="flex space-x-8 items-center">
          <div className="flex-1">
            <SearchBar
              className="flex-1"
              onChange={setSearchText}
              placeholder="Search"
              autoFocus
            />
          </div>
          <div className="flex items-center space-x-3">
            <label htmlFor="">Warehouse: </label>
            <OdooWarehouseSelector
              value={warehouseId}
              onSelect={setWarehouseId}
            />
          </div>
        </div>
      </div>

      <div className="relative flex-1 overflow-auto">
        <Orders
          warehouseId={warehouseId}
          searchText={searchText}
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
        />
      </div>
    </div>
  );
};

const Orders = ({ warehouseId, searchText, selectedRows, setSelectedRows }) => {
  const { loading, error, data } = useQuery(FETCH_ODOO_SO, {
    variables: { warehouseId },
  });
  const orderModal = useModals();
  const listContainer = useRef();
  const [listHeight, setListHeight] = useState(0);

  useEffect(() => {
    if (listContainer.current)
      setListHeight(listContainer.current.clientHeight);
  }, [loading]);

  const [generateDoc] = useDocumentGenerator();
  const [exporting, setExporting] = useState(false);
  const [exportingInter, setExportingInter] = useState(false);

  if (loading) return <Spinner text="Loading Orders from Odoo.." />;
  if (error) return <Errors error={error} />;

  const orders = data.odooSoList.filter((invoice) =>
    searchByProp(invoice, ["name", "partnerName"], searchText),
  );

  const selectedRowIds = selectedRows.map((i) => i.id);
  const isRowSelected = (id) => selectedRowIds.includes(id);

  function updateExporting(invoiceType, isLoading) {
    if (invoiceType === "Commercial Invoice") setExporting(isLoading);
    if (invoiceType === "Intercompany Invoice") setExportingInter(isLoading);
  }

  function exportHandler(invoiceType) {
    updateExporting(invoiceType, true);
    generateDoc({
      variables: {
        name: "orders",
        docType: "odoo_invoices_for_alc_tax_report",
        data: JSON.stringify({ odooSoIds: selectedRowIds, invoiceType }, "", 4),
      },
    })
      .then((res) => {
        return http.get(
          `${process.env.REACT_APP_SERVER_BASE_URL}generated-document/${res.data.generateDocument.document.id}/?pdf=1`,
          {
            responseType: "blob",
            withCredentials: true,
            timeout: 300 * 1000,
          },
        );
      })
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "orders.pdf");
        document.body.appendChild(link);
        link.click();
        updateExporting(invoiceType, false);
      })
      .catch((error) => {
        Alert("error", error.message);
        updateExporting(invoiceType, false);
      });
  }

  function openOrderDetail(order) {
    orderModal.present({
      title: order.name,
      maxWidth: "max-w-7xl",
      children: <OdooOrderDetail id={order.id} />,
    });
  }

  function renderOrders({ data, index, style }) {
    const order = data.orders[index];

    return (
      <div
        key={order.id}
        style={style}
        className="border-t flex items-center px-4"
      >
        <div className="flex items-center space-x-4 w-full">
          <input
            type="checkbox"
            checked={isRowSelected(order.id)}
            onChange={() =>
              setSelectedRows((prev) =>
                isRowSelected(order.id)
                  ? prev.filter((i) => i.id !== order.id)
                  : [...prev, order],
              )
            }
          />
          <div className="flex items-center space-x-2">
            <img style={{ height: 16 }} src={odooIcon} alt="odoo product" />
            <CharlesButton onClick={() => openOrderDetail(order)}>
              {order.name}
            </CharlesButton>
          </div>
          <div className="flex-1">{order.partnerName}</div>
          <div className="text-right w-1/5">
            {order.currencyName} {order.amountTotal}
          </div>
          <div className="text-right w-32">
            {formatDate(new Date(order.dateOrder), "YYYY-MM-DD")}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="h-full overflow-auto flex flex-col">
      <div className="flex space-x-6 mt-4">
        {selectedRows.length > 0 ? (
          <Button title="Deselect" onClick={() => setSelectedRows([])} />
        ) : null}

        <Button title="Select All" onClick={() => setSelectedRows(orders)} />

        {selectedRows.length > 0 ? (
          <>
            <Button
              title={`Export Commercial Invoices${
                selectedRows.length > 0 ? `(${selectedRows.length})` : ""
              }`}
              disabled={exporting || selectedRows.length === 0}
              onClick={() => exportHandler("Commercial Invoice")}
              loading={exporting}
            />

            <Button
              title={`Export Intercompnay Invoices${
                selectedRows.length > 0 ? `(${selectedRows.length})` : ""
              }`}
              disabled={exportingInter || selectedRows.length === 0}
              onClick={() => exportHandler("Intercompany Invoice")}
              loading={exportingInter}
            />
          </>
        ) : null}
      </div>

      <div
        className="card p-0 relative flex-1 overflow-auto mt-4"
        ref={listContainer}
      >
        <FixedSizeList
          height={listHeight}
          itemSize={40}
          width="100%"
          itemData={{ orders }}
          itemCount={orders.length}
        >
          {renderOrders}
        </FixedSizeList>
      </div>
    </div>
  );
};

export default OdooInvoiceList;
