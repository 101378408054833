import { gql } from "@apollo/client";

export const FETCH_CHINA_STOCKS = gql`
  query FETCH_CHINA_STOCKS {
    odooStocks {
      id
      name
      products: wisProducts {
        id
        name
        number
        odooId
      }
    }
  }
`;

export const FETCH_CHINA_STOCK_OVERVIEW = gql`
  query FETCH_CHINA_STOCK_OVERVIEW {
    chinaStockOverview
  }
`;

export const FETCH_CHINA_STOCK_PRODUCTS = gql`
  query FETCH_CHINA_STOCK_PRODUCTS($stockId: ID!) {
    odooStock(id: $stockId) {
      id
      name
      products: wisProducts {
        id
        name
        number
        odooId
        useSupplierStock
      }
    }
  }
`;

export const FETCH_CHINA_PRODUCT_STOCKS = gql`
  query FETCH_CHINA_PRODUCT_STOCKS($productOdooIds: [Int]) {
    chinaStocks(productOdooIds: $productOdooIds)
  }
`;

export const FETCH_ALL_PRODUCTS_FOR_STOCK = gql`
  query FETCH_ALL_PRODUCTS_FOR_STOCK {
    products(isActive: true, productType: "normal", hasOdooId: true) {
      results {
        id
        number
        name
      }
    }
  }
`;

export const CREATE_STOCK = gql`
  mutation CREATE_STOCK($name: String!) {
    createStock(name: $name) {
      stock {
        id
        name
      }
    }
  }
`;

export const ADD_PRODUCT_TO_STOCK = gql`
  mutation ADD_PRODUCT_TO_STOCK(
    $stockId: ID!
    $productId: ID!
    $useSupplierStock: Boolean!
  ) {
    addProductToStock(
      stockId: $stockId
      productId: $productId
      useSupplierStock: $useSupplierStock
    ) {
      stock {
        id
        name
        products: wisProducts {
          id
          name
          number
          odooId
          useSupplierStock
        }
      }
    }
  }
`;
