import CharlesButton from "components/charles/base";
import { Input, NativeDatePicker } from "components/Form";
import { useModals } from "ModalProvider";
import { useState } from "react";
import ChooseProductView from "./ChooseProductView";
import { BsTrash } from "react-icons/bs";
import { useMutation } from "@apollo/client";
import { AMZ_DELETE_INBOUND, AMZ_STOCK_CATEGORY_SAVE_INBOUND } from "./graphql";
import { Alert } from "components/Toast";
import { parseError } from "apollo";
import ChooseShipmentView from "./ChooseShipmentView";

const InboundFormView = ({ category, originInbound, hide }) => {
  console.log("originInbound", originInbound);
  const [inbound, setInbound] = useState(
    originInbound
      ? {
          name: originInbound.name,
          eta: originInbound.eta,
          lines: originInbound.lines.map((line) => ({
            stockId: line.stock.id,
            name: line.stock.name,
            number: line.stock.number,
            qty: line.qty,
          })),
        }
      : { name: "", eta: "", lines: [] },
  );

  const [saveInbound, saveInboundRes] = useMutation(
    AMZ_STOCK_CATEGORY_SAVE_INBOUND,
    {
      onCompleted: () => {
        Alert("success", "Inbound created successfully");
        hide();
      },
      onError(error) {
        Alert("error", parseError(error));
      },
    },
  );
  const [deleteInbound, deleteInboundRes] = useMutation(AMZ_DELETE_INBOUND, {
    onCompleted() {
      Alert("success", "Inbound deleted successfully");
      hide();
    },
    onError(error) {
      Alert("error", parseError(error));
    },
  });

  function saveHandler() {
    saveInbound({
      variables: {
        inboundId: originInbound?.id,
        stockCategoryId: category.id,
        inboundLines: inbound.lines.map((line) => ({
          stockId: line.stockId,
          qty: line.qty,
        })),
        name: inbound.name,
        eta: inbound.eta,
      },
    });
  }

  const chooseStockModal = useModals();
  const shipmentsModal = useModals();

  console.log("category", category);

  function chooseProduct() {
    chooseStockModal.present({
      title: "Choose Product",
      children: (
        <ChooseProductView
          stocks={category.stocks}
          onSelect={onChooseStock}
          hide={chooseStockModal.hide}
        />
      ),
    });
  }

  function onChooseStock(stock) {
    chooseStockModal.hide();
    setInbound((prev) => ({
      ...prev,
      stocks: [
        ...prev.stocks,
        {
          stockId: stock.id,
          name: stock.name,
          number: stock.number,
          qty: 0,
        },
      ],
    }));
  }

  function showShipments() {
    shipmentsModal.present({
      title: "Choose Shipment",
      subtitle: "Choose a shipment to Waboba AB.",
      children: (
        <ChooseShipmentView
          onSelect={onChooseShipment}
          hide={shipmentsModal.hide}
        />
      ),
    });
  }

  function onChooseShipment(shipment) {
    shipmentsModal.hide();
    const rows = shipment.shipmentInvoices.flatMap(
      (invoice) => invoice.shipmentInvoiceRows,
    );
    console.log("rows", rows);
    let lines = [];
    rows.forEach((row) => {
      const stock = category.stocks.find(
        (s) => s.odooId === row.product.odooId,
      );
      if (stock) {
        lines.push({
          stockId: stock.id,
          name: stock.name,
          number: stock.number,
          qty: row.qty,
        });
      }
    });

    setInbound((prev) => ({
      ...prev,
      name: shipment.invoiceNumber,
      eta: shipment.eta,
      lines,
    }));
  }

  function tryDeleteInbound(inboundId) {
    if (window.confirm("Are you sure you want to delete this inbound?")) {
      deleteInbound({ variables: { inboundId } });
    }
  }

  return (
    <div>
      <div className="card p-4 relative text-sm">
        <div className="flex justify-end text-sm absolute right-6 top-4">
          <CharlesButton onClick={showShipments}>
            Import from Shipment
          </CharlesButton>
        </div>

        <div>
          <table>
            <tbody>
              <tr>
                <td>
                  <label htmlFor="">Name:</label>
                </td>
                <td className="w-full">
                  <Input
                    value={inbound.name}
                    onChange={(e) =>
                      setInbound({ ...inbound, name: e.target.value })
                    }
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <label htmlFor="">ETA</label>
                </td>
                <td>
                  <NativeDatePicker
                    value={inbound.eta}
                    onChange={(e) =>
                      setInbound({ ...inbound, eta: e.target.value })
                    }
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <hr className="my-4" />

        <div className="px-2">
          <div className="mt-2 -mx-2">
            <table>
              <thead>
                <tr>
                  <th>Product</th>
                  <th className="text-right">QTY</th>
                </tr>
              </thead>
              <tbody>
                {inbound.lines.map((line, index) => (
                  <tr key={index} className="border-y dark:border-gray-700">
                    <td>
                      <div className="flex items-center space-x-2">
                        <CharlesButton
                          danger
                          onClick={() => {
                            setInbound((prev) => {
                              const newLines = prev.lines.filter(
                                (_, i) => i !== index,
                              );
                              return { ...prev, lines: newLines };
                            });
                          }}
                        >
                          <BsTrash />
                        </CharlesButton>
                        <div>
                          [{line.number}] {line.name}
                        </div>
                      </div>
                    </td>
                    <td className="text-right">
                      <Input
                        className="text-right"
                        value={line.qty}
                        onChange={(e) => {
                          setInbound((prev) => {
                            const newLines = prev.lines.map((s, i) =>
                              i === index ? { ...s, qty: e.target.value } : s,
                            );
                            return { ...prev, lines: newLines };
                          });
                        }}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className="mt-4">
            <CharlesButton onClick={chooseProduct}>+ Product</CharlesButton>
          </div>
        </div>
      </div>

      <div className="mt-6 mx-2 flex justify-between">
        <CharlesButton
          primary
          onClick={saveHandler}
          loading={saveInboundRes.loading}
        >
          Save
        </CharlesButton>

        {originInbound ? (
          <CharlesButton
            danger
            onClick={() => tryDeleteInbound(originInbound.id)}
          >
            Delete Inbound
          </CharlesButton>
        ) : null}
      </div>
    </div>
  );
};

export default InboundFormView;
