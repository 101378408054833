import { useQuery } from "@apollo/client";
import { FETCH_ASSESSMENT_SUPPLIER_ACHIEVEMENTS } from "./graphql";
import Spinner from "components/Spinner";
import Errors from "components/Errors";
import { PiCertificateLight } from "react-icons/pi";
import { RiMoneyDollarCircleLine } from "react-icons/ri";
import { MdOutlineScience, MdRecycling } from "react-icons/md";
import { GiNuclearWaste } from "react-icons/gi";
import { MdDirtyLens } from "react-icons/md";
import { TbVacuumCleaner } from "react-icons/tb";
import { FaChalkboardTeacher } from "react-icons/fa";
import { BiCertification } from "react-icons/bi";

const SupplierAchievements = () => {
  const { loading, error, data } = useQuery(
    FETCH_ASSESSMENT_SUPPLIER_ACHIEVEMENTS,
  );

  if (loading) return <Spinner />;
  if (error) return <Errors errors={error} />;

  const res = JSON.parse(data.assessmentSupplierAchievements);

  function computePercentage(item) {
    const suppliers = Object.keys(item).filter((key) => key !== "content");
    const suppliers1 = Object.entries(item).filter(
      ([key, value]) => value === 1 && suppliers.includes(key),
    ).length;
    return (suppliers1 / suppliers.length) * 100;
  }

  const icons = [
    <PiCertificateLight />,
    <RiMoneyDollarCircleLine />,
    <MdOutlineScience />,
    <GiNuclearWaste />,
    <MdDirtyLens />,
    <TbVacuumCleaner />,
    <FaChalkboardTeacher />,
    <BiCertification />,
    <MdRecycling />,
  ];

  return (
    <div>
      <h3>Supplier Achievements</h3>
      <div>Percentage of the suppliers that have achieved the following:</div>
      <div className="mt-6 grid grid-cols-3 gap-8">
        {Object.entries(res).map(([choice, item], index) => (
          <div key={choice} className="card flex flex-col justify-between">
            <div className="flex justify-between place-items-center">
              <h1>{computePercentage(item).toFixed(0)}%</h1>
              <div className="text-5xl text-green-600">{icons[index]}</div>
            </div>
            <div className="mt-4 text-sm">{item.content}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SupplierAchievements;
