import { gql } from "@apollo/client";

export const FETCH_ODOO_SO = gql`
  query FETCH_ODOO_SO($warehouseId: Int!) {
    odooSoList: odooSoListSimple(
      warehouseId: $warehouseId
      orderDateAfter: "2023-01-01 00:00:00"
    ) {
      id
      name
      partnerName
      salesStage
      address
      dateOrder
      amountTotal
      currencyName
    }
  }
`;
