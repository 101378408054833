import { gql, useQuery } from "@apollo/client";
import CharlesButton from "components/charles/base";
import Errors from "components/Errors";
import { InlineSpinner } from "components/Spinner";
import { useDateRangeDaysAgo } from "hooks/useDateRange";
import { useModals } from "ModalProvider";
import moment from "moment";
import { byName } from "utils/sort";
import XLSX from "xlsx";
import DateRangePicker from "../DateRangePicker";
import LumpSumSupplierDetail from "./LumpSumSupplierDetail";

const FETCH_PRODUCTION_LUMP_SUM = gql`
  query FETCH_PRODUCTION_LUMP_SUM($startDate: DateTime!, $endDate: DateTime!) {
    productionLumpSum(startDate: $startDate, endDate: $endDate)
  }
`;

const LumpSum = () => {
  const [{ start, end }, { setStart, setEnd }] = useDateRangeDaysAgo(30);
  const { loading, error, data } = useQuery(FETCH_PRODUCTION_LUMP_SUM, {
    variables: { startDate: start, endDate: end },
  });

  return (
    <div className="p-6">
      <div>
        <div className="flex justify-between">
          <DateRangePicker
            start={start}
            end={end}
            setStart={setStart}
            setEnd={setEnd}
          />
        </div>
        <div className="mt-2 opacity-70 text-xs">
          Suppliers has production plans during the this period.
        </div>
      </div>

      {loading ? (
        <InlineSpinner />
      ) : error ? (
        <Errors error={error} />
      ) : (
        <Content data={data.productionLumpSum} start={start} end={end} />
      )}
    </div>
  );
};

const Content = ({ data, start, end }) => {
  const res = JSON.parse(data);

  const computedSuppliers = [];

  const modal = useModals();

  function showSupplier(supplier) {
    modal.present({
      title: supplier.name,
      subtitle: `Lump Sum ${moment(start).format("YYYY-MM-DD")} ~ ${moment(end).format("YYYY-MM-DD")}`,
      children: <LumpSumSupplierDetail supplier={supplier} />,
    });
  }

  return (
    <div className="card mt-4 py-2">
      <div className="-mx-2">
        <table>
          <thead>
            <tr>
              <th>Supplier</th>
              <th className="text-right">Products Total QTY</th>
            </tr>
          </thead>
          <tbody>
            {computedSuppliers.map((supplier) => (
              <tr
                key={supplier.id}
                className="border-t border-gray-100 dark:border-gray-700"
              >
                <td>
                  <CharlesButton onClick={() => showSupplier(supplier)}>
                    {supplier.name}
                  </CharlesButton>
                </td>
                <td className="text-right">{supplier.totalQty}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default LumpSum;
