import { gql, useQuery } from "@apollo/client";
import { Button } from "components/base";
import CharlesButton from "components/charles/base";
import Errors from "components/Errors";
import SearchBar from "components/SearchBar";
import moment from "moment";
import { useContext, useState } from "react";
import { searchByProp } from "utils/search";
import { OdooContext } from "OdooProvider";
import { InlineSpinner } from "components/Spinner";
import { LiaChartLineSolid } from "react-icons/lia";
import Status from "components/Status";
import { parseError } from "apollo";

const FETCH_ODOO_PRICE_LIST = gql`
  query FETCH_ODOO_PRICE_LIST($pricelistId: Int!) {
    odooPriceListItems(pricelistId: $pricelistId)
  }
`;

const FETCH_ODOO_PRODUCT_AVG_SALES_PRICE = gql`
  query FETCH_ODOO_PRODUCT_AVG_SALES_PRICE {
    odooProductAvgSalesPriceForLast365Days
  }
`;

const PriceListDetail = ({
  priceListOdooId,
  products,
  currency,
  editProduct,
  editPriceList,
  fetchProductCostLoading,
  fetchProductCostError,
}) => {
  const [searchText, setSearchText] = useState("");
  const { odooUrl } = useContext(OdooContext);

  const { error, data } = useQuery(FETCH_ODOO_PRICE_LIST, {
    variables: { pricelistId: priceListOdooId },
    fetchPolicy: "network-only",
    skip: priceListOdooId === null,
  });
  const fetchOdooProductAvgSalesPrice = useQuery(
    FETCH_ODOO_PRODUCT_AVG_SALES_PRICE,
  );

  const odooAvgPrices = fetchOdooProductAvgSalesPrice.data
    ? JSON.parse(
        fetchOdooProductAvgSalesPrice.data
          .odooProductAvgSalesPriceForLast365Days,
      )
    : null;

  const allProducts = products.map((product) => {
    let odooPrice = 0.0;
    let res = { ...product, odooPrice };
    if (product.odooId && data) {
      const odooPriceListItems = JSON.parse(data.odooPriceListItems);
      const found = odooPriceListItems.find(
        (i) => i.product_id && i.product_id[0] === product.odooId,
      );
      if (found) odooPrice = found.fixed_price;
      res = {
        ...res,
        odooPrice,
        priceNotMatches:
          !product.price.useFixedMargin &&
          odooPrice !== product.price.finalPrice,
      };
    }

    let odooAvgPrice = 0.0;
    if (odooAvgPrices) {
      const found = odooAvgPrices.find((x) => x.product_id === product.odooId);
      if (found) odooAvgPrice = found.avg_price;
    }
    res = { ...res, odooAvgPrice };
    return res;
  });

  const productsToShow = allProducts.filter((i) =>
    searchByProp(i, ["name", "number"], searchText),
  );
  const unmatchCount = allProducts.filter((i) => i.priceNotMatches).length;
  if (products.length === 0)
    return (
      <div className="p-6 opacity-60">No Products for this price list yet.</div>
    );

  return (
    <div className="p-6 flex flex-col h-full">
      {error ? <Errors error={error} /> : null}

      <div className="flex-1 overflow-auto bg-white dark:bg-gray-800 px-6 rounded-2xl text-sm whitespace-nowrap">
        <table>
          <thead>
            <tr className="sticky top-0 bg-white dark:bg-gray-800 z-20">
              <th className="py-4">
                <div>
                  <div className="flex items-center space-x-2">
                    <div>Product ({products.length})</div>
                    <div className="flex-1">
                      <SearchBar
                        className="bg-gray-100"
                        onChange={setSearchText}
                        placeholder="Search by name, item number."
                      />
                    </div>
                  </div>
                </div>
              </th>
              <th className="text-right">
                <div className="flex justify-end items-center space-x-2">
                  {fetchProductCostLoading ? (
                    <div className="flex justify-end space-x-2">
                      <InlineSpinner size={16} text={null} />
                      <span>Loading Cost...</span>
                    </div>
                  ) : (
                    <span>Cost (Default qty, USD)</span>
                  )}
                </div>
              </th>
              <th className="text-right">Price ({currency})</th>
              <th className="text-right">Discount</th>
              {priceListOdooId ? (
                <th className="text-right text-purple-600">Odoo Price</th>
              ) : null}
              {odooAvgPrices ? (
                <th className="text-right text-purple-600">Odoo Avg Price</th>
              ) : null}
              <th className="text-right">Margin</th>
            </tr>
            {fetchProductCostError ? (
              <tr>
                <td
                  className="text-xs px-2 text-red-600 font-semibold whitespace-normal"
                  colSpan={priceListOdooId ? 9 : 6}
                >
                  Fail to load product data. {parseError(fetchProductCostError)}
                </td>
              </tr>
            ) : null}
          </thead>
          <tbody>
            {productsToShow.map((product) => (
              <tr
                key={product.id}
                className={`border-b border-gray-100 dark:border-gray-700
                        ${product.priceNotMatches ? "text-red-600" : ""}
                     `}
              >
                <td>
                  <div className="flex justify-between items-center">
                    <div className="flex space-x-2 items-center">
                      <div>
                        <div className="flex items-center space-x-3">
                          <div className="font-semibold">{product.number}</div>
                          <div className="flex space-x-2 items-center">
                            {product.images.map((i) => (
                              <img
                                src={i.url}
                                alt={i.id}
                                className="h-8 bg-gray-200 rounded"
                              />
                            ))}
                          </div>
                        </div>
                        <div className="opacity-70 text-xs whitespace-normal">
                          {product.name}
                        </div>
                      </div>
                      {product.price.state === "pending" && (
                        <Status status={product.price.state.toUpperCase()} />
                      )}
                    </div>

                    {editProduct && (
                      <Button
                        className="opacity-70"
                        size="xs"
                        title="edit"
                        onClick={() =>
                          editProduct({
                            product,
                            price: product.price.price,
                            discountPercentage:
                              product.price.discountPercentage,
                            useFixedMargin: product.price.useFixedMargin,
                            fixedMargin: product.price.fixedMargin,
                            state: product.price.state,
                          })
                        }
                      />
                    )}
                  </div>
                </td>
                <td className="text-right">
                  {product.productCost ? (
                    <div className="flex space-x-2 items-center justify-end">
                      <div className="opacity-70 text-xs">
                        {moment(product.productCost.date).format("YYYY-MM-DD")}
                      </div>
                      <div className="w-12">
                        {product.productCost.cost.toFixed(3)}
                      </div>
                    </div>
                  ) : (
                    "-"
                  )}
                </td>

                <td className="text-right">
                  <div className="flex space-x-2 items-center justify-end">
                    <div className="opacity-70 text-xs">
                      {moment(product.price.updatedAt).format("YYYY-MM-DD")}
                    </div>

                    <div className="flex items-center justify-end">
                      {product.price.useFixedMargin ? (
                        <LiaChartLineSolid className="text-teal-600" />
                      ) : null}
                      <div className="w-12">
                        {product.price.finalPrice.toFixed(2)}
                      </div>
                    </div>
                  </div>
                </td>

                <td className="text-right">
                  {product.price.discountPercentage}%
                </td>

                {priceListOdooId ? (
                  <td className="text-right">{product.odooPrice.toFixed(2)}</td>
                ) : null}

                {odooAvgPrices ? (
                  <td className="text-right">
                    {product.odooAvgPrice
                      ? product.odooAvgPrice.toFixed(2)
                      : "0.00"}
                  </td>
                ) : null}
                <td className="text-right">{product.margin?.toFixed(2)}%</td>
              </tr>
            ))}
          </tbody>

          <tfoot>
            <tr className=" sticky bottom-0 bg-white dark:bg-gray-800 z-20">
              <td colSpan={priceListOdooId ? 9 : 6}>
                <div>
                  {priceListOdooId ? (
                    <div className="text-xs mt-2 text-green-600 font-normal">
                      This price list is connected to an{" "}
                      <a
                        target="_blank"
                        className="text-purple-600"
                        rel="noreferrer"
                        href={`${odooUrl}/web#id=${priceListOdooId}&menu_id=408&cids=1&action=172&model=product.pricelist&view_type=form`}
                      >
                        Odoo price list
                      </a>{" "}
                      and will auto sync.
                    </div>
                  ) : (
                    <div className="flex font-normal text-pink-600 text-xs items-center space-x-2 mt-2">
                      <span>This price list is not connected to Odoo yet.</span>
                      <CharlesButton onClick={editPriceList}>
                        Connect Now
                      </CharlesButton>
                    </div>
                  )}

                  {unmatchCount > 0 ? (
                    <div className="text-red-600 text-xs mt-1 font-normal">
                      {unmatchCount} price not match between WIS and Odoo.
                    </div>
                  ) : null}
                </div>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>

      <div className="mt-4 opacity-70 mx-1">
        <div className="flex items-center space-x-1">
          <span>
            <LiaChartLineSolid className="text-teal-600" size={16} />
          </span>
          <span>indicates the price is calculated by fixed margin.</span>
        </div>
        <div>
          You can import a excel file to batch update prices. You must export
          the current price list first to get the correct format. Then use the
          column "N" for new prices, and "O" for new discount percentage.
        </div>
        {odooAvgPrices ? (
          <div>
            Odoo Avg Price is the average price from odoo invoice for last 365
            days.
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default PriceListDetail;
