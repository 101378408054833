import { useQuery } from "@apollo/client";
import { parseError } from "apollo";
import Errors from "components/Errors";
import Spinner from "components/Spinner";
import { NavigationBar } from "components/base";
import moment from "moment";
import { useParams, useSearchParams } from "react-router-dom";
import CharlesButton from "components/charles/base";
import { useModals } from "ModalProvider";
import Status from "components/Status";
import { FETCH_RELATED_ODOO_PO_INFO } from "./graphql";
import SupplierStockDeliveryForm from "./SupplierStockDeliveryForm";

const ProductStocksTableView = ({ product }) => {
  const productionPlanIds = product.supplierStocks.flatMap((stock) => {
    return stock.usages.map((usage) => usage.plan.id);
  });
  const { loading, error, data } = useQuery(FETCH_RELATED_ODOO_PO_INFO, {
    variables: { productionPlanIds, productOdooId: product.odooId },
  });
  const modal = useModals();

  const poInfo = data ? JSON.parse(data.productionPlanRelatedOdooPoInfo) : null;
  console.log("poInfo", poInfo);

  let rows = [];

  const stocks = [...product.supplierStocks].sort(
    (a, b) => new Date(a.readyDate) - new Date(b.readyDate),
  );

  stocks.forEach((stock) => {
    rows.push({
      id: stock.id,
      type: "STOCK",
      date: moment(stock.readyDate).format("YYYY-MM-DD"),
      name: `${stock.supplier.name} - Supplier Stock Delivery`,
      supplier: stock.supplier,
      qty: stock.qty,
      forecast: stock.latestQty,
      remark: stock.remark,
    });

    stock.usages.forEach((usage) => {
      let res = {
        type: "USAGE",
        date: moment(usage.plan.readyDate).format("YYYY-MM-DD"),
        name: usage.plan.name + " - " + usage.plan.odooObject?.objectName,
        qty: -usage.qty,
        state: usage.plan.state,
      };
      if (usage.plan.odooObject && poInfo) {
        const po = Object.values(poInfo).find((i) => {
          // if ref starts with SO, then it is a sale order.
          // if ref starts with INT, then it is a internal transfer.
          if (usage.plan.odooObject.objectType === "ORDER") {
            return i.ref === usage.plan.odooObject.objectName;
          }
          if (usage.plan.odooObject.objectType === "TRANSFER") {
            return (
              i.ref ===
              usage.plan.odooObject.objectName.replace("WH/INT/", "INT")
            );
          }
          return false;
        });
        if (po) res.po = po;
      }
      rows.push(res);
    });
  });

  console.log("rows"), rows;

  rows = rows.sort((a, b) => new Date(a.date) - new Date(b.date));

  for (let i = 0; i < rows.length; i++) {
    // add forecast value to each row.
    if (i === 0) {
      rows[i].forecast = rows[i].qty;
    } else {
      rows[i].forecast = rows[i - 1].forecast + rows[i].qty;
    }
  }
  // Now sort rows by date

  function updateDelivery(row) {
    modal.present({
      title: "Update Stock Delivery",
      children: (
        <SupplierStockDeliveryForm
          id={row.id}
          hide={modal.hide}
          initData={{
            supplierId: row.supplier.id,
            product,
            readyDate: row.date,
            qty: row.qty,
            remark: row.remark,
          }}
          complete={modal.hide}
        />
      ),
    });
  }

  return (
    <table>
      <thead>
        <tr className="sticky top-0 bg-gray-50 z-10">
          <th className="px-6 py-3">Date / 日期</th>
          <th className="px-6">Reference</th>
          <th className="px-6">
            <div className="flex items-center space-x-2">
              <div className="relative mr-2 whitespace-normal">
                <Spinner
                  text={null}
                  size={14}
                  className={loading ? "opacity-100" : "opacity-0"}
                />
                {error ? <Errors error={error} /> : null}
              </div>
              <span>PO / 采购单</span>
            </div>
          </th>
          <th className="px-6 text-right">QTY / 数量</th>
          <th className="px-6 text-right">Forecast / 预计剩余数量</th>
        </tr>
      </thead>
      <tbody>
        {rows.map((row, index) => (
          <tr
            key={index}
            className={`border-y border-gray-100 dark:border-gray-700
          ${row.type === "STOCK" ? "bg-blue-100 font-semibold border-blue-200" : ""}
          `}
          >
            <td className="px-6">{moment(row.date).format("YYYY-MM-DD")}</td>
            <td className="px-6 w-full">
              <div className="flex items-center space-x-2">
                <div className="flex flex-col items-start">
                  <div>
                    {row.type === "STOCK" ? (
                      <div className="flex justify-end">
                        <CharlesButton onClick={() => updateDelivery(row)}>
                          {row.name}
                        </CharlesButton>
                      </div>
                    ) : (
                      row.name
                    )}
                  </div>
                  {row.remark && (
                    <div className=" font-normal text-xs">{row.remark}</div>
                  )}
                </div>
                {row.type === "USAGE" && <Status status={row.state} />}
              </div>
            </td>
            <td className="px-6 w-full">
              {row.po?.name}
              {row.po ? `(${row.po.qty})` : null}
            </td>
            <td className="px-6 text-right">{row.qty}</td>
            <td className="px-6 text-right">{row.forecast}</td>
          </tr>
        ))}
      </tbody>
      <tfoot>
        <tr>
          <td colSpan="4"></td>
          <td className="px-6 text-right font-bold text-xl">
            {product.supplierStockForecast}
          </td>
        </tr>
      </tfoot>
    </table>
  );
};

export default ProductStocksTableView;
