import React from "react";
import { useQuery, gql } from "@apollo/client";
import { NavLink, Outlet } from "react-router-dom";
import Spinner from "../../../../components/Spinner";
import Errors from "../../../../components/Errors";

const FETCH_PRODUCT_GROUPS = gql`
  query FETCH_PRODUCT_GROUPS {
    groups: allProductAreas {
      id
      name
    }
  }
`;

function ProductGroups() {
  const { loading, error, data } = useQuery(FETCH_PRODUCT_GROUPS);

  if (loading) return <Spinner />;
  if (error) return <Errors error={error} />;

  const groups = [...data.groups].sort((a, b) => a.name.localeCompare(b.name));

  return (
    <div className="flex flex-1 overflow-auto">
      <div className="overflow-auto border-r border-gray-300">
        <div className="mx-6 my-4">
          {groups.map((i) => (
            <NavLink
              key={i.id}
              to={`/products/groups/${i.id}`}
              className={({ isActive }) =>
                "font-semibold hover:text-blue-600 dark:hover:text-blue-700 block border-b py-2 " +
                (isActive
                  ? "text-blue-600 dark:text-blue-700"
                  : "text-gray-700 dark:text-gray-300")
              }
            >
              {i.name}
            </NavLink>
          ))}
        </div>
      </div>
      <div className="flex-1 relative overflow-auto scrolling-touch">
        <Outlet />
      </div>
    </div>
  );
}

export default ProductGroups;
