import { Route, Routes } from "react-router-dom";
import AssessmentList from "./AssessmentList";
import AssessmentDetail from "./AssessmentDetail";
import AssessmentReports from "./reports";

const Assessment = () => {
  return (
    <Routes>
      <Route index element={<AssessmentList />} />
      <Route path="reports" element={<AssessmentReports />} />
      <Route path=":id" element={<AssessmentDetail />} />
    </Routes>
  );
};

export default Assessment;
