const ChooseProductView = ({ stocks, onSelect }) => {
  console.log("stocks", stocks);
  return (
    <div>
      <div>
        <table>
          <thead>
            <tr>
              <th>Product</th>
              <th>ASIN</th>
              <th>Barcode</th>
            </tr>
          </thead>
          <tbody>
            {stocks.map((stock) => (
              <tr
                key={stock.id}
                className="border-y hover:bg-blue-100 cursor-pointer"
                onClick={() => onSelect(stock)}
              >
                <td>
                  [{stock.number}] {stock.name}
                </td>
                <td>{stock.asin}</td>
                <td>{stock.barcode}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ChooseProductView;
