import { useQuery } from "@apollo/client";
import Page from "components/Page";
import { FETCH_ASSESSMENT_SCORE_BY_CATEGORY } from "./graphql";
import Spinner from "components/Spinner";
import Errors from "components/Errors";
import AssessmentScoresByCategoryView from "./AssessmentScoresByCategoryView";
import SupplierAchievements from "./SupplierAchievements";

const AssessmentReports = () => {
  const name = "Assessment 2024";

  const { loading, error, data } = useQuery(
    FETCH_ASSESSMENT_SCORE_BY_CATEGORY,
    { variables: { name } },
  );

  if (loading) return <Spinner />;
  if (error) return <Errors errors={error} />;

  const res = JSON.parse(data.assessmentScoresByCategory).map((i) => {
    const keys = Object.keys(i).filter((key) => key !== "supplier");
    let avg = keys.reduce((acc, key) => acc + i[key], 0) / keys.length;
    avg = Math.round(avg * 100) / 100;
    return { ...i, "Average Score": avg };
  });

  console.log("res", res);

  return (
    <Page
      title="ESG Report"
      subtitle="Note: This page is used for ESG Assessment Report 2024 only. To use it for other category, we will need to adjust the code behind the scene."
      backTo="./.."
    >
      <div className="p-8 grid gap-8">
        <AssessmentScoresByCategoryView data={res} />
        <SupplierAchievements />
      </div>
    </Page>
  );
};

export default AssessmentReports;
