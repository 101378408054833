import XLSX from "xlsx";

function exportWarehouseStock(stocks, sortedInbounds) {
  console.log("sortedInbounds", sortedInbounds);
  const data = [
    ...stocks.map((s) => ({
      product: `[ ${s.number} ] ${s.name}`,
      asin: s.asin,
      barcode: s.barcode,
      qtyPerMaster: s.qtyPerMaster,
      initialStock: s.initialQty,
      totalSold: s.totalSold,
      latestQty: s.latestQty,
      ...sortedInbounds.reduce((acc, inbound) => {
        const stockInboundLine = inbound.lines.find(
          (line) => line.stock.id === s.id,
        );
        const key = `${inbound.name}(${inbound.eta})`;
        return {
          ...acc,
          [key]: stockInboundLine ? stockInboundLine.qty : 0,
        };
      }, {}),
    })),
  ];
  console.log("data", data);
  const sheet = XLSX.utils.json_to_sheet(data);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, sheet, "stock");
  XLSX.writeFile(wb, "stock.xlsx");
}

export default exportWarehouseStock;
