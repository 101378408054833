import CharlesButton from "components/charles/base";
import InventoryOverview from "./InventoryOverview";
import { useSearchParams } from "react-router-dom";
import { FileSelector, Select } from "components/Form";
import { useModals } from "ModalProvider";
import ImportActualSalesView from "./ImportActualSalesView";
import Excel from "exceljs";
import SearchBar from "components/SearchBar";
import { useRef, useState } from "react";
import ImportBeginInventoryView from "./ImportBeginInventoryView";

const Overview = ({ categoryGroups, latestCategory }) => {
  const [searchParams, setSearchParams] = useSearchParams({
    categoryId: latestCategory.id,
  });
  const category = Object.values(categoryGroups)
    .flat()
    .find((i) => i.id === searchParams.get("categoryId"));

  const modal = useModals();
  const [searchText, setSearchText] = useState("");
  const importType = useRef(null);

  const xlsxReader = new FileReader();
  const wb = new Excel.Workbook();

  xlsxReader.onload = function (e) {
    wb.xlsx
      .load(e.target.result)
      .then(handlLoadXlsx)
      .catch((error) => {
        Alert("error", `Fail to read xlsx file. Error: ${error}`);
      });
  };

  function handlLoadXlsx(wb) {
    const ws = wb.worksheets[0];

    if (importType.current === "actualSales") {
      getActualSales(ws);
    } else if (importType.current === "beginInventory") {
      getBeginInventory(ws);
    }
  }

  function getActualSales(ws) {
    let salesItems = [];
    for (var i = 3; i <= ws.rowCount; i++) {
      const row = ws.getRow(i);
      console.log("row", row);
      const item = {
        sku: row.getCell("A").value,
        qty: parseInt(row.getCell("B").value),
      };
      salesItems.push(item);
    }

    modal.present({
      title: "Import Actual Sales",
      children: (
        <ImportActualSalesView
          category={category}
          items={salesItems}
          hide={modal.hide}
        />
      ),
    });
  }

  function getBeginInventory(ws) {
    let beginInventoryItems = [];
    for (var i = 3; i <= ws.rowCount; i++) {
      const row = ws.getRow(i);
      const item = {
        sku: row.getCell("A").value,
        qty: parseInt(row.getCell("B").value),
      };
      beginInventoryItems.push(item);
    }

    modal.present({
      title: "Import Begin Inventory",
      children: (
        <ImportBeginInventoryView
          category={category}
          items={beginInventoryItems}
          hide={modal.hide}
        />
      ),
    });
  }

  return (
    <div className="h-full flex flex-col whitespace-nowrap p-6 pt-4">
      <div className="flex justify-between items-center space-x-8">
        <div className="flex items-center space-x-3 flex-1">
          <h2 className="text-2xl font-semibold">Inventory Overview</h2>

          <Select
            value={searchParams.get("categoryId")}
            onChange={(e) => setSearchParams({ categoryId: e.target.value })}
          >
            {Object.entries(categoryGroups).map(([year, categories], index) => (
              <optgroup key={index} label={year}>
                {categories.map((category, index) => (
                  <option key={index} value={category.id}>
                    {year} - {category.name}
                  </option>
                ))}
              </optgroup>
            ))}
          </Select>

          <div className="flex-1">
            <SearchBar
              placeholder="Search by name, item number."
              value={searchText}
              onChange={setSearchText}
            />
          </div>
        </div>
        <div className="flex space-x-4 items-center">
          <FileSelector
            accept=".xlsx,.xls"
            onChange={(e) => {
              importType.current = "beginInventory";
              const file = e.target.files[0];
              xlsxReader.readAsArrayBuffer(file);
              e.target.value = null;
            }}
            title="Import Begin Inventory"
          />
          <FileSelector
            accept=".xlsx,.xls"
            onChange={(e) => {
              importType.current = "actualSales";
              const file = e.target.files[0];
              xlsxReader.readAsArrayBuffer(file);
              e.target.value = null;
            }}
            title="Import Sales File"
          />

          <CharlesButton>Download</CharlesButton>
        </div>
      </div>

      <div className="flex-1 overflow-auto whitespace-nowrap mt-4 relative">
        <InventoryOverview category={category} searchText={searchText} />
      </div>
      <div className="py-2 opacity-70 text-xs">
        <p>Begin Inv - Auto fetch data from FSI.</p>
        <p>Inbound - WIS shipments.</p>
        <p>Ecom Transfer - Speical operation, ignore for now.</p>
        <p>Actual Sales - Outbound from FSI.</p>
        <p>
          End Inv - Computed above, inventory out is actual sales if imported
          otherwise use forecased number.
        </p>
      </div>
    </div>
  );
};

export default Overview;
