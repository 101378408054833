import { gql, useQuery } from "@apollo/client";
import CharlesButton from "components/charles/base";
import Errors from "components/Errors";
import { InlineSpinner } from "components/Spinner";

const FETCH_PRODUCT_FORECASTED_SALES = gql`
  query FETCH_PRODUCT_FORECASTED_SALES($skus: [String]!, $yearMonth: String!) {
    incForecastSales(skus: $skus, yearMonth: $yearMonth)
  }
`;

const ProductForecastView = ({ sku, yearMonth, onSelectNumber }) => {
  const { loading, error, data } = useQuery(FETCH_PRODUCT_FORECASTED_SALES, {
    variables: { skus: [sku], yearMonth },
  });

  if (loading)
    return (
      <div>
        <InlineSpinner size={16} text={null} />
      </div>
    );

  if (error) return <Errors errors={error} />;

  const res = JSON.parse(data.incForecastSales);

  return (
    <div className="relative z-50 text-left space-y-2 text-sm">
      <h6 className="px-2">Sales Forecast</h6>
      <table>
        <tbody>
          <tr className="border-y">
            <td>
              <label htmlFor="">Ave Last 12 months</label>
            </td>
            <td>
              <div className="flex justify-end">
                <CharlesButton
                  onClick={() => onSelectNumber(res.avg_sales_last_12_months)}
                >
                  {res.avg_sales_last_12_months}
                </CharlesButton>
              </div>
            </td>
          </tr>
          <tr className="border-y">
            <td>
              <label htmlFor="">Same Month last year</label>
            </td>
            <td>
              <div className="flex justify-end">
                <CharlesButton
                  onClick={() => onSelectNumber(res.sales_same_month_last_year)}
                >
                  {res.sales_same_month_last_year || "-"}
                </CharlesButton>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <div className="px-2 opacity-70 text-xs whitespace-normal">
        Click on the number to use it as the forecasted sales.
      </div>
    </div>
  );
};

export default ProductForecastView;
