import React, { useState } from "react";
import { useMutation, gql } from "@apollo/client";
import { formatDate } from "react-day-picker/moment";
import writtenNumber from "written-number";
import { CheckBox } from "components/base";
import { DatePicker, Input, Select, Text } from "components/Form";
import { CURRENCIES } from "utils/invoice";
import { Alert } from "components/Toast";
import http from "utils/http";
import CharlesButton from "components/charles/base";

const GENERATE_DOCUMENT = gql`
  mutation GENERATE_DOCUMENT(
    $name: String!
    $docType: String!
    $data: String!
  ) {
    generateDocument(name: $name, docType: $docType, data: $data) {
      document {
        id
        name
        docType
        data
      }
    }
  }
`;

const CreateInvoiceForm = ({ so }) => {
  const [vat, setVat] = useState("");
  const [invoiceType, setInvoiceType] = useState("Intercompany Invoice");
  const [invoiceDate, setInvoiceDate] = useState(new Date(so.dateOrder));
  const [invoiceNumber, setInvoiceNumber] = useState(so.name.replace("SO", ""));
  const [notifyParty, setNotifyParty] = useState("");

  const [notes, setNotes] = useState("");
  const [shippingTerms, setShippingTerms] = useState(so.incotermName);
  const [packingListNumber, setPackingListNumber] = useState("");
  const [generateDocument] = useMutation(GENERATE_DOCUMENT, {
    onError: (error) => Alert("error", error.message),
  });
  const [exportingPDF, setExportingPDF] = useState(false);

  const partner = so.partner;
  const commercialINvoiceConsignee = `${so.partnerName}
${partner.street}
${partner.street2}
${partner.city} ${partner.zip}
${partner.countryName}
VAT: ${[partner.vat]}`;

  const intercompanyInvoiceConsignee = `WABOBA AB
Sweden
Hornsgatan 110
117 26 Stockholm
Sweden
VAT: GB324122352
EORI: GB324122352000`;

  const consignee =
    invoiceType === "Intercompany Invoice"
      ? intercompanyInvoiceConsignee
      : commercialINvoiceConsignee;

  const shippingAddress = `${so.partnerName}
${so.partnerShipping.street}
${so.partnerShipping.street2}
${so.partnerShipping.city} ${so.partnerShipping.zip}
${so.partnerShipping.countryName}`;

  let currencySymbol = null;
  const currency = CURRENCIES.find((i) => i.code === so.currencyName);
  if (currency) {
    currencySymbol = currency.symbol;
  }

  const [lines, setLines] = useState(
    so.lines.map((line) => {
      const quantityPerCarton = line.product?.quantityPerCarton ?? 1;
      const cartons = line.qty / quantityPerCarton;
      const productNetWeight = line.product?.ctnNetWeight ?? 0;
      const productGrossWeight = line.product?.ctnGrossWeight ?? 0;

      return {
        ...line,
        selected: true,
        name: line.name.replace(/\[.*\]/, ""),
        number: line.defaultCode,
        defaultQty: line.qty,
        hsCode: line.hsCode
          ? line.hsCode
          : line.product &&
              line.product.productLine &&
              line.product.productLine.hsCodeForEu
            ? line.product.productLine.hsCodeForEu
            : "",
        netWeight: productNetWeight * cartons,
        grossWeight: productGrossWeight * cartons,
      };
    }),
  );

  const sayTotal = (value) => {
    let s = "";
    const [a, b] = (value + "").split(".");
    if (parseInt(a, 10) > 0) {
      s = writtenNumber(a);
    }
    if (parseInt(b, 10) > 0) {
      s += " AND " + writtenNumber(b) + " CENTS.";
    }
    return s;
  };

  const hsCodes = lines.reduce((res, line) => {
    if (res.indexOf(line.hsCode) === -1) return [...res, line.hsCode];
    return res;
  }, []);

  const hsCodeGroups = hsCodes.map((hsCode) => {
    const groupLines = lines
      .filter((i) => i.hsCode === hsCode)
      .map((line) => {
        const subtotal = Number(
          (line.priceUnit * line.qty * (1 - line.discount / 100)).toFixed(2),
        );
        return {
          ...line,
          subtotal,
          priceUnitWithCurrency: `${currencySymbol} ${line.priceUnit}`,
          priceSubtotalWithCurrency: `${currencySymbol} ${subtotal.toFixed(2)}`,
        };
      });
    const totalQty = groupLines
      .filter((i) => i.selected)
      .reduce((res, line) => res + line.qty, 0);
    const totalNetWeight = groupLines
      .filter((i) => i.selected)
      .reduce((res, line) => res + line.netWeight, 0);
    const totalGrossWeight = groupLines
      .filter((i) => i.selected)
      .reduce((res, line) => res + line.grossWeight, 0);
    const totalPriceUntax = groupLines
      .filter((i) => i.selected)
      .reduce((res, line) => res + line.priceSubtotal, 0);
    const totalPrice = groupLines
      .filter((i) => i.selected)
      .reduce((res, line) => res + line.subtotal, 0);
    const totalPriceWithCurrency = `${currencySymbol} ${totalPrice.toFixed(2)}`;
    return {
      hsCode,
      lines: groupLines,
      totalQty,
      totalNetWeight,
      totalGrossWeight,
      totalPriceUntax,
      totalPrice,
      totalPriceWithCurrency,
    };
  });

  const soTotalUnTax = hsCodeGroups.reduce(
    (prev, group) => prev + group.totalPriceUntax,
    0,
  );
  const clientOrderRef = so.clientOrderRef === "false" ? "" : so.clientOrderRef;

  const soTotalNetWeight = hsCodeGroups.reduce(
    (prev, group) => prev + group.totalNetWeight,
    0,
  );
  const soTotalGrossWeight = hsCodeGroups.reduce(
    (prev, group) => prev + group.totalGrossWeight,
    0,
  );

  const soTotal = hsCodeGroups.reduce(
    (prev, group) => prev + group.totalPrice,
    0,
  );

  const soTotalQty = hsCodeGroups.reduce(
    (prev, group) => prev + group.totalQty,
    0,
  );

  const amountUntaxedWithCurrency = `${currencySymbol} ${soTotalUnTax.toFixed(2)}`;

  const amountTaxWithCurrency = `${currencySymbol} ${so.amountTax.toFixed(2)}`;
  const totalWithCurrency = `${currencySymbol} ${soTotal.toFixed(2)}`;
  const sayTotalString =
    `SAY TOTAL ${currency.saying} ${sayTotal(soTotal.toFixed(2))}`.toUpperCase();

  const selectedHsCodeGroups = hsCodeGroups
    .map((group) => ({
      ...group,
      lines: group.lines.filter((line) => line.selected),
    }))
    .filter((group) => group.lines.length > 0);

  const variables = {
    docType: "odoo_invoice",
    name: so.name,
    data: JSON.stringify(
      {
        vat,
        invoiceType,
        hsCodeGroups: selectedHsCodeGroups,
        packingListNumber,
        so,
        clientOrderRef,
        invoiceNumber,
        invoiceDate: formatDate(invoiceDate, "YYYY-MM-DD"),
        consignee,
        shippingAddress,
        amountUntaxedWithCurrency,
        amountTaxWithCurrency,
        totalWithCurrency,
        notifyParty,
        notes,
        shippingTerms,
        totalQty: soTotalQty,
        soTotalNetWeight,
        soTotalGrossWeight,
        sayTotalString,
      },
      "",
      4,
    ),
  };

  function exportPdf() {
    setExportingPDF(true);
    generateDocument({ variables })
      .then((res) =>
        http.get(
          `${process.env.REACT_APP_SERVER_BASE_URL}generated-document/${res.data.generateDocument.document.id}/?pdf=1`,
          {
            responseType: "blob",
            withCredentials: true,
          },
        ),
      )
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", so.name.replace("SO", "CI") + ".pdf");
        document.body.appendChild(link);
        link.click();
        setExportingPDF(false);
      })
      .catch((e) => {
        Alert("error", e.message);
        setExportingPDF(false);
      });
  }

  return (
    <div className="text-sm">
      <h2 className="">Generate Invoice</h2>

      <div className="leading-normal flex mt-4 space-x-6">
        <div className="w-1/4">
          <p>
            <i>
              <b>Sweden</b>
            </i>
          </p>
          <p>Hornsgatan 110</p>
          <p>117 26 Stockholm</p>
          <p>Sweden</p>
          <div>
            <Text
              className="mt-2"
              value={vat}
              onChange={(e) => setVat(e.target.value)}
            />
          </div>
        </div>
        <div className="w-1/4">
          <p>
            <i>
              <b>Netherlands Warehouse</b>
            </i>
          </p>
          <p>ALC Warehousing b.v.</p>
          <p>Ondernemingsweg 68</p>
          <p>2404 HN Alphen aan den Rijn</p>
          <p>The Netherlands</p>
        </div>
      </div>

      {so.partner.countryName === "United Kingdom" ? (
        <div className="mt-4 flex">
          <div className="w-1/4 space-y-2">
            <div className="flex items-center">
              <label className="mr-3">VAT:</label>
              <div>SE556503946701</div>
            </div>
            <div className="flex items-center">
              <label className="mr-3">EORI:</label>
              <div>SE5565039467</div>
            </div>
          </div>
          <div className="w-1/4 space-y-2">
            <div className="flex items-center">
              <label className="mr-3">VAT:</label>
              <div>GB324122352</div>
            </div>
            <div className="flex items-center">
              <label className="mr-3">EORI:</label>
              <div>GB324122352000</div>
            </div>
          </div>
        </div>
      ) : null}

      <h1 className="mt-12 mb-4">{invoiceType}</h1>

      <div className="flex items-center space-x-4 mb-4">
        <label htmlFor="">Type: </label>
        <Select
          value={invoiceType}
          onChange={(e) => setInvoiceType(e.target.value)}
        >
          <option value="Intercompany Invoice">Intercompany Invoice</option>
          <option value="Commercial Invoice">Commercial Invoice</option>
        </Select>
      </div>

      <div className="flex justify-between items-center">
        <div className="flex items-center space-x-2">
          <h4>Inv No.</h4>
          <Input
            value={invoiceNumber}
            onChange={(e) => setInvoiceNumber(e.target.value)}
          />
        </div>
        <div className="flex justify-end items-center">
          <label className="mr-4">Invoice Date: </label>
          <DatePicker
            value={invoiceDate}
            onDayChange={(selectedDay) => {
              setInvoiceDate(selectedDay);
            }}
          />
        </div>
      </div>

      <div className="flex mt-8 space-x-16">
        <div className="flex-1 flex space-x-16 items-start mb-4">
          <div>
            <label htmlFor="">Consignee's Name and Address:</label>
            <div className="whitespace-pre-wrap mt-2">{consignee}</div>
          </div>
        </div>

        <div className="space-y-2">
          <div className="flex justify-end items-center">
            <label className="mr-4">Customer PO#: </label>
            {clientOrderRef}
          </div>

          <div className="flex justify-end items-center">
            <label className="mr-4">Shipping Terms: </label>
            <Input
              className="w-auto"
              value={shippingTerms}
              onChange={(e) => setShippingTerms(e.target.value)}
            />
          </div>

          <div className="flex justify-end items-center">
            <label className="mr-4">Packinglist Number: </label>
            <Input
              className="w-auto text-center"
              value={packingListNumber}
              onChange={(e) => setPackingListNumber(e.target.value)}
            />
          </div>
        </div>
      </div>

      <div>
        <label htmlFor="">Shipping Address:</label>
        <div className="whitespace-pre-wrap mt-2">{shippingAddress}</div>
      </div>

      <div className="py-8">
        <table className="with-border">
          <thead className="whitespace-nowrap">
            <tr className="border-b">
              <th>
                <CheckBox
                  checked={lines.every((i) => i.selected)}
                  onChange={(checked) =>
                    setLines((prev) =>
                      prev.map((i) => ({ ...i, selected: checked })),
                    )
                  }
                />
              </th>
              <th className="text-left">Item NO</th>
              <th className="text-left">HS Code</th>
              <th className="text-left">Item Description</th>
              <th className="text-right">Qty</th>
              <th className="text-right">N.W.(KG)</th>
              <th className="text-right">G.W.(KG)</th>
              <th className="text-right">Price/PCS</th>
              <th className="text-right">Discount %</th>
              <th className="text-right">Total Price</th>
            </tr>
          </thead>
          <tbody>
            {hsCodeGroups.map((group, index) => (
              <React.Fragment key={index}>
                {group.lines.map((line) => (
                  <tr
                    key={line.id}
                    className={line.selected ? "" : " opacity-50"}
                  >
                    <td>
                      <CheckBox
                        checked={line.selected}
                        onChange={(checked) =>
                          setLines((prev) =>
                            prev.map((i) =>
                              i.id === line.id
                                ? {
                                    ...i,
                                    selected: checked,
                                  }
                                : i,
                            ),
                          )
                        }
                      />
                    </td>
                    <td className="whitespace-nowrap">{line.number}</td>
                    <td>
                      <Input
                        value={line.hsCode}
                        onChange={(e) =>
                          setLines((prev) =>
                            prev.map((i) =>
                              i.id === line.id
                                ? { ...i, hsCode: e.target.value }
                                : i,
                            ),
                          )
                        }
                      />
                    </td>
                    <td>{line.name}</td>
                    <td className="text-right">
                      <Input
                        className="text-right"
                        value={line.qty}
                        onChange={(e) => {
                          setLines((prev) =>
                            prev.map((i) => {
                              if (i.id === line.id) {
                                const qty = parseInt(e.target.value);
                                return {
                                  ...i,
                                  qty: qty
                                    ? qty <= i.defaultQty
                                      ? qty
                                      : i.defaultQty
                                    : 0,
                                };
                              }
                              return i;
                            }),
                          );
                        }}
                      />
                    </td>
                    <td className="text-right whitespace-nowrap">
                      {line.netWeight.toFixed(3)}
                    </td>
                    <td className="text-right whitespace-nowrap">
                      {line.grossWeight.toFixed(3)}
                    </td>
                    <td className="text-right whitespace-nowrap">
                      {line.priceUnitWithCurrency}
                    </td>
                    <td className="text-right whitespace-nowrap">
                      {line.discount}
                    </td>
                    <td className="text-right whitespace-nowrap">
                      {line.priceSubtotalWithCurrency}
                    </td>
                  </tr>
                ))}
                {!group.hsCode ? (
                  <tr>
                    <td colSpan={5}></td>
                  </tr>
                ) : selectedHsCodeGroups.length > 1 ? (
                  <tr className="font-bold">
                    <td colSpan={4}></td>
                    <td className="text-right">{group.totalQty}</td>
                    <td className="text-right">
                      {group.totalNetWeight.toFixed(3)}
                    </td>
                    <td className="text-right">
                      {group.totalGrossWeight.toFixed(3)}
                    </td>
                    <td></td>
                    <td></td>
                    <td className="text-right">
                      {group.totalPriceWithCurrency}
                    </td>
                  </tr>
                ) : null}
              </React.Fragment>
            ))}

            <tr className="text-base font-bold border-t whitespace-nowrap">
              <td className="py-3" colSpan={4}>
                Total:
              </td>
              <td className="text-right">{soTotalQty}</td>
              <td className="text-right">{soTotalNetWeight.toFixed(3)}</td>
              <td className="text-right">{soTotalGrossWeight.toFixed(3)}</td>
              <td></td>
              <td></td>
              <td className="text-right">{totalWithCurrency}</td>
            </tr>
            <tr>
              <td colSpan={10} className="uppercase">
                {sayTotalString}
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div>
        <label htmlFor="">Notify Party</label>
        <Text
          className="mt-2"
          value={notifyParty}
          onChange={(e) => setNotifyParty(e.target.value)}
        />
      </div>

      <div className="mt-6">
        <label htmlFor="">Notes:</label>
        <Text
          className="mt-2"
          value={notes}
          onChange={(e) => setNotes(e.target.value)}
        />
      </div>

      <div className="my-8">
        <p>Product: Made in China</p>
        <p>In terms of VAT: 0% Export</p>
      </div>

      <div className="text-sm opacity-70">
        WIS will try to find the HSCODE first in odoo product, if not found,
        then it will try to find in the wis product line hs code for EU.
      </div>

      <div className="flex flex-col items-end my-16 space-y-8">
        <div className="flex justify-center space-x-8">
          <CharlesButton onClick={exportPdf} loading={exportingPDF}>
            Generate CI PDF
          </CharlesButton>
        </div>
      </div>
    </div>
  );
};

export default CreateInvoiceForm;
