import { useMutation } from "@apollo/client";
import { UPDATE_ASSESSMENT_STATUS } from "./graphql";
import { Alert } from "components/Toast";
import { parseError } from "apollo";
import CharlesButton from "components/charles/base";

const CompleteAssessmentView = ({ id, dismiss, followUpQuestions }) => {
  const [updateAssessmentStatus, updateAssessmentStatusRes] = useMutation(
    UPDATE_ASSESSMENT_STATUS,
    {
      variables: {
        id,
        status: "completed",
      },
      onCompleted() {
        Alert("success", "Assessment is Completed.");
        dismiss();
      },
      onError(error) {
        Alert("error", parseError(error));
      },
    },
  );

  console.log("followUpQuestions", followUpQuestions);

  return (
    <div className="space-y-6">
      <div className="text space-y-2">
        <p>
          Are you sure to complete this assessment? Once you complete the
          assessment, the supplier will receive an email notification.
        </p>
        <p>
          Make sure we have completed all our work before completing the
          assessment.
        </p>
        <p>You can select the questions to follow up in next assessment.</p>
      </div>

      {followUpQuestions.length > 0 && (
        <div className="py-4">
          <h5>Questions to follow up next time</h5>
          <div className="text-sm divide-opacity-70">
            Details will be included in the email notification.
          </div>
          <div className="mt-4">
            {followUpQuestions.map((question) => (
              <div
                key={question.id}
                className="flex items-center space-x-2 py-2 border-t"
              >
                {question.question.question}
              </div>
            ))}
          </div>
        </div>
      )}

      <hr />

      <div>
        <CharlesButton
          primary
          onClick={updateAssessmentStatus}
          loading={updateAssessmentStatusRes.loading}
        >
          Complete Assessment
        </CharlesButton>
      </div>
    </div>
  );
};

export default CompleteAssessmentView;
