const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className="px-6 py-3 rounded-2xl bg-opacity-80 bg-white dark:bg-gray-800 dark:bg-opacity-80">
        <div className="text-sm font-semibold">{label}</div>
        <div>
          {payload.map((data, index) => (
            <div key={index}>{`${data.dataKey}: ${data.value}`}</div>
          ))}
        </div>
      </div>
    );
  }

  return null;
};

export default CustomTooltip;
