import Errors from "components/Errors";
import Spinner from "components/Spinner";
import { Button, NavigationBar } from "components/base";
import { useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import StockDetail from "./StockDetail";
import NewStockForm from "../NewStockForm";
import { FETCH_AMAZON_STOCK_CATEGORY } from "../graphql";
import StockCategoryForm from "./StockCategoryForm";
import { useModals } from "ModalProvider";
import { FiCircle } from "react-icons/fi";
import exportWarehouseStock from "./exportXlxs";
import CharlesButton from "components/charles/base";
import InboundFormView from "./InboundFormView";

const StockCategoryDetail = () => {
  const { id } = useParams();
  const { loading, error, data } = useQuery(FETCH_AMAZON_STOCK_CATEGORY, {
    variables: { id },
  });

  const modal = useModals();
  const inboundModal = useModals();

  if (loading) return <Spinner />;
  if (error) return <Errors error={error} />;

  const category = data.amazonStockCategory;

  const stocks = category.stocks
    .map((stock) => {
      const totalSold = stock.initialQty - stock.latestQty;
      return { ...stock, totalSold: -totalSold };
    })
    .sort((a, b) => a.number.localeCompare(b.number));

  const sortedInbounds = [...category.inbounds].sort((a, b) => {
    return new Date(a.eta) - new Date(b.eta);
  });
  const totalForecast = stocks.reduce((acc, s) => acc + s.forecast, 0);

  function showStockDetail(stock) {
    modal.present({
      title: `Stock ${stock.number}`,
      children: <StockDetail stock={stock} hide={modal.hide} />,
    });
  }

  function tryAddStock() {
    modal.present({
      title: "Create Stock",
      children: <NewStockForm categoryId={category.id} hide={hide} />,
    });
  }

  function tryDuplicate() {
    modal.present({
      title: "Duplicate Stock Category",
      children: (
        <StockCategoryForm fromStockCategory={category} hide={modal.hide} />
      ),
    });
  }

  function tryCreateInbound() {
    inboundModal.present({
      title: "Create Inbound",
      children: (
        <InboundFormView category={category} hide={inboundModal.hide} />
      ),
      isBeingPresented: true,
    });
  }

  function showInboundDetail(inbound) {
    inboundModal.present({
      title: inbound.name,
      children: (
        <InboundFormView
          category={category}
          originInbound={inbound}
          hide={inboundModal.hide}
        />
      ),
    });
  }

  return (
    <div className="flex-1 overflow-auto flex flex-col">
      <NavigationBar
        title={`[${category.regions}] ${category.name}`}
        rightButtons={
          <div className="flex items-center space-x-4">
            <CharlesButton onClick={tryAddStock}>+ Stock</CharlesButton>
            <CharlesButton onClick={tryCreateInbound}>+ Inbound</CharlesButton>
            <Button title="Duplicate" onClick={tryDuplicate} />
            <Button
              title="Export XLXS"
              onClick={() => exportWarehouseStock(stocks, sortedInbounds)}
            />
          </div>
        }
      />
      <div className="flex-1 overflow-auto">
        <table className="bg-white dark:bg-gray-800 rounded whitespace-nowrap">
          <thead>
            <tr className="text-left z-20">
              <th className="bg-gray-100 dark:bg-gray-800 sticky top-0 left-0 z-10 px-6 py-3">
                Product
              </th>
              <th className="bg-gray-100 dark:bg-gray-800 sticky top-0 px-4">
                ASIN
              </th>
              <th className="bg-gray-100 dark:bg-gray-800 sticky top-0 px-4">
                Barcode
              </th>
              <th className="bg-gray-100 dark:bg-gray-800 sticky top-0 px-4 text-right">
                Qty / Master
              </th>
              <th className="bg-gray-100 dark:bg-gray-800 sticky top-0 px-4 text-right">
                Inital Stock
              </th>
              <th className="bg-gray-100 dark:bg-gray-800 sticky top-0 px-4 text-right">
                Total Sold
              </th>
              <th className="bg-gray-100 dark:bg-gray-800 sticky top-0 z-20 px-4 text-right">
                Latest Stock
              </th>
              {sortedInbounds.map((inbound) => (
                <th
                  key={inbound.id}
                  className="bg-gray-100 dark:bg-gray-800 sticky top-0 z-20 px-4 text-right"
                >
                  <div className="flex flex-col items-end">
                    <CharlesButton onClick={() => showInboundDetail(inbound)}>
                      {inbound.name}
                    </CharlesButton>
                    <div className=" font-normal opacity-70">{inbound.eta}</div>
                  </div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {stocks.map((stock) => (
              <tr
                key={stock.id}
                className="border-t dark:border-gray-700 cursor-pointer hover:text-blue-600 active:text-blue-700"
                onClick={() => showStockDetail(stock)}
              >
                <td className="px-6 sticky left-0 bg-white dark:bg-gray-800">
                  <div className="flex space-x-2 items-center">
                    {stock.odooId ? (
                      <FiCircle className="text-purple-600" size={14} />
                    ) : null}
                    <div>
                      {stock.number ? `[${stock.number}] ` : null}
                      {stock.name}
                    </div>
                  </div>
                </td>
                <td className="px-4">{stock.asin}</td>
                <td className="px-4">{stock.barcode}</td>
                <td className="px-4 text-right">{stock.qtyPerMaster}</td>
                <td className="px-4 text-right">{stock.initialQty}</td>
                <td className="px-4 text-right">{stock.totalSold}</td>
                <td className="px-4 text-right">{stock.latestQty}</td>
                {sortedInbounds.map((inbound) => {
                  const stockInbound = inbound.lines.find(
                    (s) => s.stock.id === stock.id,
                  );
                  const qty = stockInbound ? stockInbound.qty : 0;
                  return (
                    <td key={inbound.id} className="px-4 text-right">
                      {qty}
                    </td>
                  );
                })}
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr className="border-t dark:border-gray-700 font-semibold text-base">
              <td className="sticky left-0 bottom-0 bg-gray-50 dark:bg-gray-800 py-3 px-6 z-10">
                Total
              </td>
              <td
                colSpan={3}
                className="sticky bg-gray-50 dark:bg-gray-800 py-3 bottom-0 px-4"
              ></td>
              <td className="sticky bg-gray-50 dark:bg-gray-800 py-3 bottom-0 text-right px-4">
                {stocks.reduce((acc, stock) => acc + stock.initialQty, 0)}
              </td>
              <td className="sticky bg-gray-50 dark:bg-gray-800 py-3 bottom-0 text-right px-4">
                {stocks.reduce((acc, stock) => acc + stock.totalSold, 0)}
              </td>
              <td className="sticky bg-gray-50 dark:bg-gray-800 py-3 bottom-0 text-right px-4">
                {stocks.reduce((acc, stock) => acc + stock.latestQty, 0)}
              </td>
              {sortedInbounds.map((inbound) => {
                const total = inbound.lines.reduce((acc, line) => {
                  return acc + line.qty;
                }, 0);
                return (
                  <td
                    key={inbound.id}
                    className="sticky bg-gray-50 dark:bg-gray-800 py-3 bottom-0 text-right px-4"
                  >
                    {total}
                  </td>
                );
              })}
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  );
};

export default StockCategoryDetail;
