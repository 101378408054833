import CharlesButton from "components/charles/base";
import { useModals } from "ModalProvider";
import StartSelfAssessmentView from "./StartSelfAssessmentView";
import StartRectificationView from "./StartRectificationView";
import CompleteAssessmentView from "./CompleteAssessmentView";

const text = {
  DRAFT:
    'This assessment is in `Draft` state. You can start self assessment by clicking "Start Self Assessment" button. Make sure you have set the due date and correct extra info and save before starting the assessment.',
  SELF_ASSESSMENT:
    'This assessment is in self assessment state. Supplier is doing self assessment. Once the supplier submit the assessment, it will change to "Reviewing" state. Note that in wis you can manually answer the questions for supplier if you want to.',
  REVIEWING:
    "This assessment is in `Reviewing` state. We should review the assessment and give feedback to supplier. You are supposed to select the questions by toggle the `Required Rectification` button that require rectification for each question and give feedback to supplier.",
  IN_RECTIFICATION:
    'This assessment is `In Rectification` state. Supplier will submit rectification for the questions we give feedback. You could continue `Send Rectification Feedback` any time if we are not satisty the supplier feedback. This state might take long, we can change the status to "Completed" state if we think the assessment is good enough.',
  COMPLETED:
    "This assessment is in completed. You could view the assessment result.",
};

const AssessmentStatusNoteView = ({ id, status, categories }) => {
  const modal = useModals();
  const completeModal = useModals();

  function startSelfAssessment() {
    modal.present({
      title: "Start Assessment",
      center: true,
      children: <StartSelfAssessmentView id={id} dismiss={modal.hide} />,
    });
  }

  function startRectification() {
    modal.present({
      title: "Start Rectification",
      center: false,
      children: (
        <StartRectificationView
          id={id}
          categories={categories}
          dismiss={modal.hide}
        />
      ),
    });
  }

  function sendRectificationFeedback() {
    modal.present({
      title: "Send Rectification Feedback",
      center: false,
      children: (
        <StartRectificationView
          id={id}
          categories={categories}
          dismiss={modal.hide}
        />
      ),
    });
  }

  function completeAssessment() {
    const allQuestions = categories.flatMap((category) => category.questions);
    const followUpQuestions = allQuestions.filter((i) => i.followUpNextTime);
    completeModal.present({
      title: "Complete Assessment",
      maxWidth: "max-w-5xl",
      children: (
        <CompleteAssessmentView
          id={id}
          followUpQuestions={followUpQuestions}
          dismiss={modal.hide}
        />
      ),
    });
  }

  return (
    <div className="card px-6 py-4">
      <h5>Status</h5>
      <div className="mt-2">{text[status]}</div>

      <div className="my-3">
        {status === "DRAFT" ? (
          <CharlesButton onClick={startSelfAssessment}>
            Start Self Assessment
          </CharlesButton>
        ) : status === "REVIEWING" ? (
          <CharlesButton onClick={startRectification}>
            Start Rectification
          </CharlesButton>
        ) : status === "IN_RECTIFICATION" ? (
          <div>
            <CharlesButton onClick={sendRectificationFeedback}>
              Send Rectification Feedback
            </CharlesButton>
            <CharlesButton onClick={completeAssessment}>
              Complete Assessment
            </CharlesButton>
          </div>
        ) : null}
      </div>

      <div className="text-xs opacity-70">
        You can always go to admin to manually update the status manually.
      </div>
    </div>
  );
};

export default AssessmentStatusNoteView;
