import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useQuery, gql, useMutation } from "@apollo/client";
import Spinner from "components/Spinner";
import Errors from "components/Errors";
import Image from "components/Image";
import SearchBar from "components/SearchBar";
import { searchByProp } from "utils/search";
import { DatePicker } from "components/Form";
import { Button } from "components/base";
import { AppContext } from "App";
import { Alert } from "components/Toast";
import { formatDate } from "react-day-picker/moment";
import { useModals } from "ModalProvider";
import ProductDetail from "./ProductDetail";
import { byName } from "utils/sort";
import { SAVE_PRODUCT } from "pages/manage/products/All/graphql";
import { PRODUCT_EDITOR } from "utils/permissions";

const FETCH_COST_TRENDS = gql`
  query FETCH_COST_TRENDS {
    allProductlines(activeForWis: true) {
      id
      name
      salesPriceNotes
    }
    allProducts(productType: "normal", isActive: true) {
      id
      odooId
      name
      number
      defaultQty
      productLine {
        id
      }
      isActive
    }
    priceStartFromDate
  }
`;

const TrendsContext = createContext({});

const Trends = () => {
  const { loading, error, data } = useQuery(FETCH_COST_TRENDS);
  const [searchText, setSearchText] = useState(
    process.env.NODE_ENV === "development" ? "302C01_A" : "",
  );
  const [startDate, setStartDate] = useState(
    localStorage.getItem("costTrends:startDate")
      ? new Date(localStorage.getItem("costTrends:startDate"))
      : new Date().addDays(-30 * 6),
  );
  const [selectedProductLine, setSelectedProductLine] = useState(null);
  const modal = useModals();

  useEffect(() => {
    if (startDate)
      localStorage.setItem(
        "costTrends:startDate",
        formatDate(startDate, "YYYY-MM-DD"),
      );
  }, [startDate]);

  if (loading) return <Spinner />;
  if (error) return <Errors error={error} />;

  const getlineProducts = (lineId) =>
    data.allProducts.filter(
      (i) =>
        i.isActive &&
        i.productLine &&
        i.productLine.id === lineId &&
        i.odooId !== null,
    );

  const productLines = data.allProductlines
    .map((line) => {
      const lineProducts = getlineProducts(line.id);
      const stringForSearch = lineProducts
        .map((p) => `${p.name} ${p.number}`)
        .join(" ");
      return { ...line, stringForSearch, products: lineProducts };
    })
    .filter((i) => i.products.length > 0)
    .filter((i) => searchByProp(i, ["name", "stringForSearch"], searchText))
    .sort(byName);

  function showProductDetail(product) {
    modal.present({
      title: `[${product.number}] ${product.name}`,
      maxWidth: "max-w-6xl",
      children: <ProductDetail startDate={startDate} product={product} />,
    });
  }

  return (
    <TrendsContext.Provider
      value={{ startDate, selectedProductLine, setSelectedProductLine }}
    >
      <div className="">
        <div className="p-6 flex items-center space-x-8">
          <div className="flex items-center">
            <label className="pr-3">Start Date: </label>
            <DatePicker
              value={startDate}
              onDayChange={(selectedDay) => {
                if (selectedDay) setStartDate(selectedDay);
              }}
            />
          </div>

          <div className="flex-1">
            <SearchBar
              autoFocus
              placeholder="Search by name or item number"
              value={searchText}
              onChange={setSearchText}
            />
          </div>
        </div>

        <div className="flex-1 overflow-auto">
          <div className="h-full p-6 pt-0 overflow-auto">
            {productLines.map((line) => (
              <ProductLineDetail
                key={line.id}
                line={line}
                products={line.products}
                showProductDetail={showProductDetail}
              />
            ))}
          </div>
        </div>
      </div>
    </TrendsContext.Provider>
  );
};

const ProductLineDetail = ({ line, products, showProductDetail }) => {
  const { selectedProductLine, setSelectedProductLine } =
    useContext(TrendsContext);

  return (
    <div
      key={line.id}
      className="mb-8 bg-white dark:bg-gray-800 border border-gray-100 dark:border-gray-800 rounded-xl p-4 shadow-sm"
      data-testid="productline-row"
    >
      <div className="flex text-right items-end space-x-8 px-4 py-1 font-bold ">
        <div className="w-1/2 ">
          <div>
            <div
              className={`
                     my-1 flex items-end cursor-pointer
                     ${selectedProductLine && selectedProductLine.id === line.id ? "text-blue-600" : "hover:text-blue-600"}
                     `}
              onClick={() =>
                setSelectedProductLine((prev) =>
                  prev && prev.id === line.id ? null : line,
                )
              }
            >
              <h2 className="pr-4">{line.name}</h2>
              <Image src={line.mainImage} size="w-8 h-8" />
            </div>
            {line.salesPriceNotes ? (
              <div className="text-left text-red-600">
                {line.salesPriceNotes}
              </div>
            ) : null}
          </div>
        </div>
        <div className="uppercase w-32 flex-grow"></div>
      </div>

      <div>
        {products.map((variant) => (
          <ProductVariant
            key={variant.id}
            variant={variant}
            showProductDetail={() => showProductDetail(variant)}
          />
        ))}
      </div>
    </div>
  );
};

const ProductVariant = ({ variant, showProductDetail }) => {
  const { hasPermission } = useContext(AppContext);
  const { startDate } = useContext(TrendsContext);
  const [saveProduct, saveProductRes] = useMutation(SAVE_PRODUCT, {
    onError(error) {
      Alert("error", error.message);
    },
  });

  return useMemo(
    () => (
      <div className="border-t border-gray-100 dark:border-gray-700 py-1 px-4">
        <div className="flex space-x-8 items-center">
          <div className="w-1/2 flex-grow flex items-center space-x-3">
            <div>
              [ {variant.number} ] {variant.name}
            </div>

            <Button title="more" onClick={showProductDetail} />

            {hasPermission(PRODUCT_EDITOR) ? (
              <Button
                title="hide"
                color="red"
                disabled={saveProductRes.loading}
                loading={saveProductRes.loading}
                onClick={() => {
                  if (
                    window.confirm("Are you sure to deactivate this product?")
                  )
                    saveProduct({
                      variables: {
                        id: variant.id,
                        simpleFields: {
                          isActive: false,
                        },
                      },
                    });
                }}
              />
            ) : null}
          </div>
        </div>
      </div>
    ),
    [startDate],
  );
};

export default Trends;
