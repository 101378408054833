import { useQuery } from "@apollo/client";
import { FETCH_WABOBA_AB_SHIPMENTS } from "./graphql";
import Spinner from "components/Spinner";
import Errors from "components/Errors";
import Status from "components/Status";

const ChooseShipmentView = ({ onSelect }) => {
  const { loading, error, data } = useQuery(FETCH_WABOBA_AB_SHIPMENTS);

  if (loading) return <Spinner />;
  if (error) return <Errors error={error} />;

  const shipments = data.shipments.results;

  return (
    <div>
      <div className="-mx-2">
        <table>
          <tbody>
            {shipments.map((shipment) => (
              <tr
                key={shipment.id}
                className="border-y hover:bg-blue-100 cursor-pointer"
                onClick={() => onSelect(shipment)}
              >
                <td>{shipment.invoiceNumber}</td>
                <td>{shipment.eta}</td>
                <td>
                  <Status status={shipment.status} />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ChooseShipmentView;
