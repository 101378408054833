import { gql } from "@apollo/client";

export const FETCH_ASSESSMENT_SCORE_BY_CATEGORY = gql`
  query FETCH_ASSESSMENT_SCORE_BY_CATEGORY($name: String!) {
    assessmentScoresByCategory(rootCategoryName: $name)
  }
`;

export const FETCH_ASSESSMENT_SUPPLIER_ACHIEVEMENTS = gql`
  query FETCH_ASSESSMENT_SUPPLIER_ACHIEVEMENTS {
    assessmentSupplierAchievements
  }
`;
