import { useParams, useSearchParams } from "react-router-dom";
import { FETCH_CHINA_STOCK_PRODUCTS } from "./graphql";
import Spinner from "components/Spinner";
import Errors from "components/Errors";
import { useQuery } from "@apollo/client";
import { NavigationBar } from "components/base";
import ChinaStocksProductTableView from "./ChinaStocksProductTableView";
import CharlesButton from "components/charles/base";
import { useModals } from "ModalProvider";
import AddProductToStockView from "./AddProductToStockView";
import FilterStockProductsView from "./FilterStockProductsView";
import { useState } from "react";
import { useEffect } from "react";

const ChinaStockProducts = () => {
  const { stockId } = useParams();
  const { loading, error, data } = useQuery(FETCH_CHINA_STOCK_PRODUCTS, {
    variables: { stockId },
  });
  const [searchParams] = useSearchParams();

  const modal = useModals();
  const [selectedProductIds, setSelectedProductIds] = useState([]);

  useEffect(() => {
    if (data) {
      setSelectedProductIds(data.odooStock.products.map((p) => p.id));
    }
  }, [data]);

  if (loading) return <Spinner />;
  if (error) return <Errors error={error} />;

  function tryAddProduct(stock) {
    modal.present({
      title: "Add Product",
      center: true,
      children: <AddProductToStockView hide={modal.hide} stock={stock} />,
    });
  }

  const adminLink = `${process.env.REACT_APP_SERVER_ADMIN_URL}stock/stock/${stockId}/change/`;

  // Some issues. Disabled for now.
  function showFilter() {
    modal.present({
      title: "Choose Products",
      center: true,
      children: (
        <FilterStockProductsView
          products={data.odooStock.products}
          initialProductIds={selectedProductIds}
          onSelectProductIds={setSelectedProductIds}
          hide={modal.hide}
        />
      ),
    });
  }

  const params = Object.fromEntries(searchParams.entries());
  const products = data.odooStock.products.filter((i) => {
    if (params.productOdooId) {
      return i.odooId === parseInt(params.productOdooId);
    }
    return true;
  });

  return (
    <div className="flex flex-col flex-1 overflow-auto">
      <NavigationBar
        title={data.odooStock.name}
        rightButtons={
          <div className="flex items-center space-x-4">
            <CharlesButton onClick={() => tryAddProduct(data.odooStock)}>
              + Add Product
            </CharlesButton>

            <a href={adminLink} target="_blank">
              Admin
            </a>
          </div>
        }
      />
      {data.odooStock.products.length === 0 ? (
        <div className="p-6">No products found in this stock.</div>
      ) : (
        <ChinaStocksProductTableView products={products} />
      )}
    </div>
  );
};

export default ChinaStockProducts;
